import { useState } from "react";
import Button from "../../components/Common/Button";
import CardOverview from "../../components/Common/CardOverview";
import Icon from "../../components/Common/Icon";
import { IzobrazevanjeDetailType } from "../../types/Izobrazevanje/IzobrazevanjeDetailType";
import { IzobrazevanjePrijavaListDto } from "../../types/IzobrazevanjePrijava/IzobrazevanjePrijavaListDto";
import Nullable from "../../types/Nullable";
import getApiExceptionObjectFromAxiosError from "../../utils/ProcessException";
import { useFetchIzobrazevanjeDetail } from "../izobrazevanje/IzobrazevanjeHooks";
import IzobrazevanjePrijavaActionOdjava from "./IzobrazevanjePrijavaActionOdjava";
import IzobrazevanjePrijavaActionPrijava from "./IzobrazevanjePrijavaActionPrijava";
import { useAddIzobrazevanjePrijava, useFetchPrijavaByUporabnikIzobrazevanjeId, useUpdateIzobrazevanjePrijava } from "./IzobrazevanjePrijavaHooks";
import styled from "styled-components";

const StyledIzobrazevanjePrijavaActions = styled.div`
    width: 50%;
    p{
        margin-top: 0;
        margin-bottom: 1rem;
        font-weight: 350;
        font-size: 1.2rem;
    }
    .smaller {
            font-size: 1rem;
    }
    .warning {
        font-size: 0.8rem;
        color: grey;
    }
    .button {
        margin-bottom: 1rem;
    }
    .success{
        font-size: 1.3rem;
        font-weight: 700;
        display: inline-flex;
        align-items: center;
        color: ${props => props.theme.colors.primary};
        .check{
            color: ${props => props.theme.colors.primary};
            font-size: 4rem !important;
            margin-right: 1rem;
            margin-left: 0;
        }
    }
    @media (max-width: 900px){
        width: 90%;
    }
`

interface IzobrazevanjePrijavaActionsArgs {
    izobrazevanje: Nullable<IzobrazevanjeDetailType>
}

const IzobrazevanjePrijavaActions = ({ izobrazevanje }: IzobrazevanjePrijavaActionsArgs) => {
    const { data: prijavaData, status: prijavaStatus, isSuccess: prijavaIsSuccess } = useFetchPrijavaByUporabnikIzobrazevanjeId(izobrazevanje?.izobrazevanjeId);
    const [justRegistered, setJustRegistered] = useState(false);

    const addIzobrazevanjePrijavaMutatied = useAddIzobrazevanjePrijava();
    const updateIzobrazevanjePrijavaMutatied = useUpdateIzobrazevanjePrijava();

    function prijava(izobrazevanjeId: Nullable<number>) {
        if (!izobrazevanjeId) {
            // TODO: Throw error
            throw new Error("CardOverview.prijava - izobrazevanjeId is null");
        }
        addIzobrazevanjePrijavaMutatied.mutate(izobrazevanjeId);
        setJustRegistered(true);
    }

    function odjava(izobrazevanjePrijavaId: Nullable<number>) {
        if (!izobrazevanjePrijavaId) {
            // TODO: Throw error
            throw new Error("CardOverview.odjava - izobrazevanjePrijavaId is null");
        }
        updateIzobrazevanjePrijavaMutatied.mutate(izobrazevanjePrijavaId);
    }

    return (
        <StyledIzobrazevanjePrijavaActions>
            {
                (prijavaIsSuccess && izobrazevanje && prijavaData &&
                    ((prijavaData && prijavaData.izobrazevanjePrijavaId && prijavaData.izobrazevanjePrijavaId > 0)) ?
                    (
                        <IzobrazevanjePrijavaActionOdjava
                            izobrazevanjePrijavaId={prijavaData.izobrazevanjePrijavaId}
                            izobrazevanje={izobrazevanje}
                            handleOdjavaClick={odjava}
                            justRegistered={justRegistered}
                            apiException={getApiExceptionObjectFromAxiosError(updateIzobrazevanjePrijavaMutatied?.error)} />
                    )
                    :
                    (prijavaIsSuccess && izobrazevanje && prijavaData &&
                        <IzobrazevanjePrijavaActionPrijava
                            izobrazevanjeId={izobrazevanje.izobrazevanjeId}
                            prijavaOmejena={izobrazevanje.prijavaOmejena}
                            steviloProstihMest={prijavaData.steviloProstihMest}
                            beforeDeadlinePrijavaOdjava={izobrazevanje.beforeDeadlinePrijavaOdjava}
                            handlePrijavaClick={prijava}
                            apiException={getApiExceptionObjectFromAxiosError(addIzobrazevanjePrijavaMutatied?.error)} />
                    )
                )}
        </StyledIzobrazevanjePrijavaActions>
    );
}

export default IzobrazevanjePrijavaActions;