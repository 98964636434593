import { useState } from "react";
import Button from "../../components/Common/Button";
import Icon from "../../components/Common/Icon";
import ApiException from "../../types/ApiException";
import { IzobrazevanjeDetailType } from "../../types/Izobrazevanje/IzobrazevanjeDetailType";

interface IIzobrazevanjePrijavaActionOdjavaArgs {
    izobrazevanjePrijavaId: number;
    izobrazevanje: IzobrazevanjeDetailType;
    handleOdjavaClick: (izobrazevanjePrijavaId: number) => void;
    justRegistered: boolean;
    apiException: ApiException | null;
}

const IzobrazevanjePrijavaActionOdjava = ({ izobrazevanjePrijavaId, izobrazevanje, handleOdjavaClick, justRegistered, apiException }: IIzobrazevanjePrijavaActionOdjavaArgs) => {

    return (
        <>
            <div className="right registered">
                {justRegistered &&
                    <>
                        <p className="success">
                            <Icon name="done" full className="check" />
                            <span>Prijava je bila uspešna.</span>
                        </p>
                        <p className="smaller">Izobraževanje je premaknjeno v zavihek "moje prijave"</p>
                    </>
                }
                {izobrazevanje.isVTeku && izobrazevanje.povezava &&
                    <div className="right">
                        <p>Kliknite tukaj za začetek izobraževanja:</p>
                        <a href={izobrazevanje.povezava} target="_blank" rel="noreferrer">
                            <Button>začni</Button>
                        </a>
                    </div>
                }
                {!izobrazevanje.isZakljuceno && !izobrazevanje.isVTeku &&
                    (<>
                        <p className="smaller">Za odjavo kliknite spodnji gumb:</p>
                        <Button
                            tertiary
                            onClick={() => handleOdjavaClick(izobrazevanjePrijavaId)}
                            disabled={!izobrazevanje.beforeDeadlinePrijavaOdjava}
                        >Odjava</Button>
                        <p className="warning">* Odjavite se lahko do 3 dni pred začetkom izobraževanja</p>
                    </>)}
                {(apiException?.Message &&
                    <div>
                        <span className="error">
                            <span className="red">{apiException?.Message}</span>
                        </span>
                    </div>)}
            </div>
        </>
    );
}

export default IzobrazevanjePrijavaActionOdjava;