const AppConfig = {
    baseApiUrl: process.env.REACT_APP_BaseApiUrl,
};



const MsalConfig = {
    auth: {
        clientId: process.env.REACT_APP_ClientId ?? '',
        authority: process.env.REACT_APP_Authority,
        redirectUri: process.env.REACT_APP_RedirectUri,
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "localStorage", // Da deluje "Single sign-out", mora biti določena vrednost "localStorage".
        storeAuthStateInCookie: false
    },
    system: {
        allowRedirectInIframe: true // Da deluje "Single sign-out".
    }
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
const LoginRequest = {
    scopes: ["User.Read"]
};

const AccessTokenRequest = {
    scopes: [process.env.REACT_APP_Scopes ?? '']
};

export default AppConfig;
export { MsalConfig, LoginRequest, AccessTokenRequest };