import styled from "styled-components"

const StyledFilterItem = styled.div`

    display: inline-flex;
    align-items: center;

    input, select{
        border-radius: 4px;
        border: 1px solid ${props => props.theme.colors.border};
        padding: 0.4rem 0.8rem;
        margin: 0.5rem 0;
        margin-right: 4vw;
    } 

    label{
        display: inline-block;
        margin-right: 1rem;
    }

`

interface FilterItemArgs {
    children: React.ReactNode
}

const FilterItem = ({ children }: FilterItemArgs) => {
    return (
        <StyledFilterItem>
            {children}
        </StyledFilterItem>
    )
}

export default FilterItem