import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";

// Da deluje "Single sign-out" (Front-channel logout URL).
const SessionEnd = () => {
    const { instance } = useMsal();
    
    // Automatically on page load
    useEffect(() => {
        instance.logoutRedirect({
            onRedirectNavigate: () => {
                // Return false to stop navigation after local logout
                return false;
            }
        });
    }, []);

    return (null);
}

export default SessionEnd;