import React, { useEffect, useState } from "react";
import { IzobrazevanjeFormType } from "../../types/Izobrazevanje/IzobrazevanjeFormType";
import Button from "../../components/Common/Button";
import FormActions from "../../components/Form/FormActions";
import FormContainer from "../../components/Form/FormContainer";
import FormItems from "../../components/Form/FormItems";
import { IzobrazevanjeTipListType } from "../../types/IzobrazevanjeTip/IzobrazevanjeTipListType";
import Nullable from "../../types/Nullable";
import { PodrocjeFormType } from "../../types/Podrocje/PodrocjeFormType";
import { Link, useNavigate } from "react-router-dom";
import ApiException from "../../types/ApiException";
import TextInput from "../../components/Form/TextInput";
import DropdownInput from "../../components/Form/DropdownInput";
import TextArea from "../../components/Form/TextArea";
import DateRange from "../../components/Form/DateRange";
import CheckboxInput from "../../components/Form/CheckboxInput";
import CheckboxItem from "../../components/Form/CheckboxItem";
import Modal from "../../components/Common/Modal";
import { IzobrazevanjeStatus } from "../../utils/Enums";

type IzobrazevanjeFormArgs = {
    izobrazevanje: IzobrazevanjeFormType;
    izobrazevanjeTipi: IzobrazevanjeTipListType[];
    podrocja: Nullable<PodrocjeFormType[]>;
    submittedDraft: (izobrazevanje: IzobrazevanjeFormType) => void;
    submittedPublish?: (izobrazevanje: IzobrazevanjeFormType) => void;
    deleted?: (izobrazevanje: IzobrazevanjeFormType) => void;
    apiException: ApiException | null;
}

const IzobrazevanjeForm = ({ izobrazevanje, izobrazevanjeTipi, podrocja, submittedDraft, submittedPublish, deleted, apiException }: IzobrazevanjeFormArgs) => {
    const [izobrazevanjeState, setIzobrazevanjeState] = useState({ ...izobrazevanje });
    const nav = useNavigate();
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const onSubmitDraft: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        e.preventDefault();
        submittedDraft(izobrazevanjeState);
    }

    const onSubmitPublish: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        if (submittedPublish) {
            e.preventDefault();
            submittedPublish(izobrazevanjeState);
        }
    }

    const showModal: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        e.preventDefault();
        setShowDeleteModal(true);
    }

    const onDelete: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        if (deleted) {
            e.preventDefault();
            deleted(izobrazevanjeState);
        }
    }

    function setPodrocja(e: React.ChangeEvent<HTMLInputElement>, all = false): void {
        if (all) {
            if (e.target.checked) {
                setIzobrazevanjeState({ ...izobrazevanjeState, podrocjaIds: podrocja!.map(x => x.podrocjeId) })
            } else {
                setIzobrazevanjeState({ ...izobrazevanjeState, podrocjaIds: [] })
            }
        } else {
            if (e.target.checked)
                setIzobrazevanjeState({ ...izobrazevanjeState, podrocjaIds: izobrazevanjeState.podrocjaIds.concat(Number(e.target.value)) })
            else if (izobrazevanjeState.podrocjaIds.includes(Number(e.target.value)))
                setIzobrazevanjeState({ ...izobrazevanjeState, podrocjaIds: izobrazevanjeState.podrocjaIds.filter(pi => pi !== Number(e.target.value)) })
        }
    }

    function setSelectIzobrazevanjeTipId(e: React.ChangeEvent<HTMLSelectElement>) {
        const it = izobrazevanjeTipi.filter(it => it.izobrazevanjeTipId === Number(e.target.value))[0];
        setIzobrazevanjeState({
            ...izobrazevanjeState,
            izobrazevanjeTipId: Number(e.target.value),
            tockeSt: it.stTockPrivzeto,
            udelezenciSt: izobrazevanjeState.prijavaOmejena ? it.stMestPrivzeto : null
        })
    }

    function setUdelezenciSt(e: React.ChangeEvent<HTMLInputElement>) {
        const it = izobrazevanjeTipi.filter(it => it.izobrazevanjeTipId === Number(izobrazevanjeState.izobrazevanjeTipId))[0];
        setIzobrazevanjeState({
            ...izobrazevanjeState,
            prijavaOmejena: e.target.checked,
            udelezenciSt: e.target.checked ? it.stMestPrivzeto : null
        })
    }

    return (
        <FormContainer>
            <Modal
                visible={showDeleteModal}
                message="Ali ste prepričani da želite izbrisati to izobraževanje?"
                actionMessage="izbriši"
                actionIcon="delete"
                onCancel={() => setShowDeleteModal(false)}
                onConfirm={onDelete}
            />
            {submittedPublish &&
                <FormActions>
                    <Button startIcon="arrow_back_ios" onClick={() => nav('/izobrazevanje/list')}>nazaj na seznam</Button>
                    <Button className="right" onClick={onSubmitPublish} startIcon="done" tertiary>objavi</Button>
                </FormActions>
            }
            <FormItems labelsWidth="27%">
                <TextInput
                    value={izobrazevanjeState.naziv}
                    width="350px"
                    placeholder="Naziv"
                    label="Naziv izobraževanja"
                    required={true}
                    err={apiException}
                    errField="Naziv"
                    onChange={(e) => setIzobrazevanjeState({ ...izobrazevanjeState, naziv: e.target.value })}
                />
                <TextInput
                    value={izobrazevanjeState.predavatelj ? izobrazevanjeState.predavatelj : ""}
                    width="350px"
                    placeholder="Predavatelj"
                    label="Predavatelj"
                    required={false}
                    err={apiException}
                    errField="Predavatelj"
                    onChange={(e) => setIzobrazevanjeState({ ...izobrazevanjeState, predavatelj: e.target.value })}
                />
                <DateRange
                    label="Datum od/do"
                    odValue={izobrazevanjeState.datumCasOd ? new Date(izobrazevanjeState.datumCasOd) : null}
                    doValue={izobrazevanjeState.datumCasDo ? new Date(izobrazevanjeState.datumCasDo) : null}
                    odOnChange={function (date: Date | null | undefined) {   
                        setIzobrazevanjeState({ ...izobrazevanjeState, datumCasOd: date ? new Date(date) : null })
                    }}
                    err={apiException}
                    errField="DatumCasOd"
                    doOnChange={function (date: Date | null | undefined) {
                        setIzobrazevanjeState({ ...izobrazevanjeState, datumCasDo: date ? new Date(date) : null })                     
                    }}
                />
                <TextArea
                    width="350px"
                    height="100px"
                    label="Opis"
                    placeholder="Opis"
                    value={izobrazevanjeState.opis ? izobrazevanjeState.opis : ""}
                    onChange={(e) => setIzobrazevanjeState({ ...izobrazevanjeState, opis: e.target.value })}
                    required={false}
                />
                <DropdownInput
                    label="Tip izobraževanja"
                    value={izobrazevanjeState.izobrazevanjeTipId}
                    onChange={(e) => setSelectIzobrazevanjeTipId(e)}
                    required={true}
                >
                    {izobrazevanjeTipi && izobrazevanjeTipi.map((it) => (
                        <option key={it.izobrazevanjeTipId} value={it.izobrazevanjeTipId}>{it.naziv}</option>
                    ))}
                </DropdownInput>
                <TextInput
                    label="Št. točk"
                    placeholder="0"
                    type="number"
                    isWholeNumber
                    width="100px"
                    min={0}
                    value={izobrazevanjeState.tockeSt == null ? "" : izobrazevanjeState.tockeSt}
                    onChange={(e) => setIzobrazevanjeState({ ...izobrazevanjeState, tockeSt: Number(e.target.value) })}
                    err={apiException}
                    errField={"TockeSt"}
                />
                <CheckboxInput label="Na voljo za" err={apiException} errField={"PodrocjaIds"}>
                    {podrocja && podrocja.map((p) => (
                        <CheckboxItem
                            key={p.podrocjeId}
                            id={`p_${p.podrocjeId}`}
                            value={p.podrocjeId}
                            name={`p_${p.podrocjeId}`}
                            checked={izobrazevanjeState.podrocjaIds && izobrazevanjeState.podrocjaIds.includes(p.podrocjeId)}
                            onChange={(e) => setPodrocja(e)}
                            label={p.naziv}
                        />
                    ))}
                    <CheckboxItem
                        key={0}
                        id={'izberi vse'}
                        value={'izberi vse'}
                        name={'izberi vse'}
                        checked={izobrazevanjeState.podrocjaIds && izobrazevanjeState.podrocjaIds.length === podrocja?.length}
                        onChange={(e) => setPodrocja(e, true)}
                        label={'izberi vse'}
                    />
                </CheckboxInput>
                <CheckboxInput label="Omejitev prijav" errField="OmejitevPrijav">
                    <CheckboxItem
                        value="omejitevPrijav"
                        name="omejitevPrijav"
                        checked={!!izobrazevanjeState.prijavaOmejena}
                        onChange={(e) => setUdelezenciSt(e)}
                    />
                </CheckboxInput>
                {izobrazevanjeState.prijavaOmejena &&
                    <TextInput
                        width="100px"
                        label="Št. udeležencev"
                        placeholder="0"
                        type="number"
                        isWholeNumber
                        min={0}
                        value={izobrazevanjeState.udelezenciSt == null ? "" : izobrazevanjeState.udelezenciSt}
                        onChange={(e) => setIzobrazevanjeState({ ...izobrazevanjeState, udelezenciSt: Number(e.target.value) })}
                        err={apiException}
                        errField="UdelezenciSt"
                        required={true}
                    />
                }
                <TextInput
                    label="Povezava"
                    type="text"
                    width="350px"
                    value={izobrazevanjeState.povezava ? izobrazevanjeState.povezava : ""}
                    onChange={(e) => setIzobrazevanjeState({ ...izobrazevanjeState, povezava: e.target.value })}
                    err={apiException}
                    errField="Povezava"
                />
            </FormItems>
            <FormActions>
                {deleted && izobrazevanjeState.status == IzobrazevanjeStatus.VPripravi &&
                    <Button onClick={showModal} startIcon="delete" tertiary>izbriši</Button>
                }
                <div></div>
                <div className="right">
                    <Button onClick={onSubmitDraft} startIcon="save" primary>shrani</Button>
                    <Link to={`/izobrazevanje/list`}>
                        <Button startIcon="close" cancel>prekliči</Button>
                    </Link>
                </div>
            </FormActions>
        </FormContainer>
    );
}

export default IzobrazevanjeForm;