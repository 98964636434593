import Section from "../../components/Common/Section";
import Divider from "../../components/Common/Divider";
import Heading from "../../components/Common/Heading";
import getApiExceptionObjectFromAxiosError from "../../utils/ProcessException";
import IzobrazevanjeForm from "./IzobrazevanjeForm";
import { useFetchIzobrazevanjeWithSifranti, useAddIzobrazevanjeDraft, useAddIzobrazevanjePublish } from "./IzobrazevanjeHooks";
import ApiStatus from "../../components/ApiStatus";

const IzobrazevanjeAdd = () => {
    const { data, status, isSuccess } = useFetchIzobrazevanjeWithSifranti(0);
    const addIzobrazevanjeMutatiedDraft = useAddIzobrazevanjeDraft();

    if (!isSuccess)
        return (
            <Section>
                <Heading>Dodajanje izobraževanja
                    <Divider />
                </Heading>
                <ApiStatus status={status}></ApiStatus>
            </Section>
        )

    return (
        <Section>
            <Heading>
                Dodajanje izobraževanja
                <Divider />
                <p>
                    <span className="red">*</span>
                    polja, označena z zvezdico, so obvezna
                </p>
            </Heading>
            <IzobrazevanjeForm
                izobrazevanje={data.izobrazevanje}
                izobrazevanjeTipi={data.izobrazevanjeTipi}
                podrocja={data.podrocja}
                submittedDraft={(i) => addIzobrazevanjeMutatiedDraft.mutate(i)}
                apiException={getApiExceptionObjectFromAxiosError(addIzobrazevanjeMutatiedDraft?.error)}
            />
        </Section>
    );
}

export default IzobrazevanjeAdd;