import Button from "../../components/Common/Button";
import ApiException from "../../types/ApiException";

interface IzobrazevanjePrijavaActionPrijavaArgs {
    izobrazevanjeId: number;
    prijavaOmejena: boolean;
    steviloProstihMest: number;
    beforeDeadlinePrijavaOdjava: boolean;
    handlePrijavaClick: (izobrazevanjeId: number) => void;
    apiException: ApiException | null;
}

const IzobrazevanjePrijavaActionPrijava = ({ izobrazevanjeId, prijavaOmejena, steviloProstihMest, beforeDeadlinePrijavaOdjava, handlePrijavaClick, apiException }: IzobrazevanjePrijavaActionPrijavaArgs) => {

    return (
        <>
            <div className="right unregistered">
                {prijavaOmejena &&
                    <>
                        <p>Število udeležencev za to izobraževanje je omejeno.</p>
                        <p>Število prostih mest: <b>{`${(steviloProstihMest)}`}</b></p>
                    </>
                }
                {(!prijavaOmejena || (prijavaOmejena && steviloProstihMest > 0)) && beforeDeadlinePrijavaOdjava && (
                    <>
                        <p className="smaller">Za prijavo kliknite spodnji gumb:</p>
                        <Button onClick={() => handlePrijavaClick(izobrazevanjeId)}>Prijava</Button>
                        <p className="warning">* Odjavite se lahko do 3 dni pred začetkom izobraževanja</p>
                    </>
                )}
                {(apiException?.Message &&
                    <div>
                        <span className="error">
                            <span className="red">{apiException?.Message}</span>
                        </span>
                    </div>)}
            </div>
        </>
    );
}

export default IzobrazevanjePrijavaActionPrijava;