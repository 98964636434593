import { PostsPerPage } from './../../utils/Enums';
import { useEffect, useState } from "react"

const usePagination = <T>(data: Array<T> | undefined, postsPerPage: number = PostsPerPage) => {
    const [paginate, setPaginate] = useState(true)
    const [currPage, setCurrPage] = useState(1)
    const [currData, setCurrData] = useState(data?.slice((currPage - 1) * postsPerPage, currPage * postsPerPage))
    const pageCount = Math.ceil(data?.length! / postsPerPage)

    useEffect(() => {
        if (paginate)
            setCurrData(data?.slice((currPage - 1) * postsPerPage, currPage * postsPerPage))
        else
            setCurrData(data)
    }, [data, currPage, postsPerPage, paginate])

    const onPageChange = (selectedItem: { selected: number }) => {
        setCurrPage(selectedItem.selected + 1)
    }

    return { currData, paginate, setPaginate, currPage, setCurrPage, onPageChange, pageCount }
}

export default usePagination