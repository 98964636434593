import Section from "../../../components/Common/Section";
import { PodrocjeFormType } from "../../../types/Podrocje/PodrocjeFormType";
import getApiExceptionObjectFromAxiosError from "../../../utils/ProcessException";
import PodrocjeForm from "./PodrocjeForm";
import { useAddPodrocje } from "./PodrocjeHooks";

const PodrocjeAdd = () => {
    const addPodrocjeMutatied = useAddPodrocje();

    const podrocje: PodrocjeFormType = {
       podrocjeId: 0,
        naziv: "",
        //status: 1
    };

    return (
        <Section>
            <PodrocjeForm
                podrocje={podrocje}
                submitted={(p) => addPodrocjeMutatied.mutate(p)}
                apiException={getApiExceptionObjectFromAxiosError(addPodrocjeMutatied?.error)} />
        </Section>
    );
}

export default PodrocjeAdd;