import styled from 'styled-components';

const StyledDivider = styled.div`
    height: 2px;
    width: 100%;
    background-color: #ebebeb;
    margin-top: 0.3rem;
`

const Divider = () => {
    return <StyledDivider />
}
export default Divider