import { MouseEventHandler } from 'react';
import styled from 'styled-components';
import Button from './Button';

interface StyledModalProps {
    visible: boolean;
}

const StyledModal = styled.div<StyledModalProps>`
    transition: all .3s cubic-bezier(0.16, 1, 0.3, 1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    .card {
        font-size: 1.2rem;
        background-color: ${props => props.theme.colors.background};
        padding: 1rem 2rem;
        box-sizing: border-box;
        margin-bottom: 1rem;
        border: ${props => props.theme.colors.border} 1px solid;
        border-radius: 4px;
        p{
            margin-bottom: 2rem;
        }
    }
    .buttons{
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
    }
    .action {
        margin-right: 1rem;
    }
    ${props => props.visible ? `
        opacity: 1;
        visibility: visible;
    ` : `
        opacity: 0;
        visibility: hidden;
    `}
    .card{
        display:block;
    }
`

interface ModalArgs {
    visible: boolean,
    message: string,
    actionMessage: string,
    onCancel: MouseEventHandler<HTMLButtonElement>,
    onConfirm: MouseEventHandler<HTMLButtonElement>,
    actionIcon: string
}

const Modal = ({ visible, message, onCancel, onConfirm, actionMessage, actionIcon }: ModalArgs) => {
    return (
        <StyledModal className='modal' visible={visible}>
            <div className='card'>
                <p>{message}</p>
                <div className='buttons'>
                    <div></div>
                    <div>
                        <Button className='action' startIcon={actionIcon} onClick={onConfirm} tertiary>{actionMessage}</Button>
                        <Button startIcon='close' onClick={onCancel} cancel>Prekliči</Button>
                    </div>
                </div>
            </div>
        </StyledModal>
    )
}

export default Modal