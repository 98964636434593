import styled from 'styled-components'

const StyledCardGroup = styled.div`

    .cards{
        display: flex;
        flex-wrap: wrap;
    }

    h2{
        font-size: 20px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 1rem;
    }

`

interface CardGroupArgs {
    title: string
    children: React.ReactNode
}

const CardGroup = ({ title, children }: CardGroupArgs) => {
    return (
        <StyledCardGroup>
            <h2>{title}</h2>
            <div className='cards'>
                {children}
            </div>
        </StyledCardGroup>
    )
}

export default CardGroup