import { Link } from "react-router-dom";
import ApiStatus from "../../../components/ApiStatus";
import useFetchPodrocjeAll from "./PodrocjeHooks";
import Table from "../../../components/Common/Table";
import Button from "../../../components/Common/Button";
import Heading from "../../../components/Common/Heading";
import Section from "../../../components/Common/Section";
import Divider from "../../../components/Common/Divider";
import { PodrocjeListType } from "../../../types/Podrocje/PodrocjeListType";
import useSort from "../../../components/Common/SortingHooks";
import usePagination from "../../../components/Common/PaginationHooks";
import TableHeader from "../../../components/Common/TableHeader";

const PodrocjeList = () => {
    const { data, status, isSuccess } = useFetchPodrocjeAll();

    const { setSort, sortedData, sortColumn, sortIcon } = useSort<PodrocjeListType>(data);
    const { pageCount, paginate, setPaginate, currData, onPageChange } = usePagination<PodrocjeListType>(sortedData);

    const TableHeaderProps = { sortColumn: sortColumn, onClickHandler: setSort, sortIcon: sortIcon };

    if (!isSuccess)
        return (
            <Section>
                <Heading>Pregled organizacij
                    <Divider />
                </Heading>
                <ApiStatus status={status}></ApiStatus>
            </Section>
        )

    return (
        <Section>
            <Heading>Pregled organizacij
                <Divider />
            </Heading>

            <Table
                pageCount={pageCount}
                onPageChange={onPageChange}
                paginate={paginate}
                setPaginate={setPaginate}
            >
                <thead>
                    <tr>
                        <TableHeader
                            {...TableHeaderProps}
                            indexName="naziv"
                            width="70%"
                            left
                        >Naziv</TableHeader>
                        <TableHeader
                            {...TableHeaderProps}
                            indexName="status"
                        >Status</TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {currData && currData.map((PodrocjeListType) => (
                        <tr key={PodrocjeListType.podrocjeId}>
                            <td className="txtLeft">
                                <Link to={`/sifrant/organizacija/edit/${PodrocjeListType.podrocjeId}`}>
                                    {PodrocjeListType.naziv}
                                </Link>
                            </td>
                            <td className="txtCenter">{PodrocjeListType.statusNaziv}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Link to="/sifrant/organizacija/add">
                <Button startIcon="add">
                    dodaj
                </Button>
            </Link>
            <br />
        </Section>
    );
}

export default PodrocjeList;