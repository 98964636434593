import { useState } from "react";
import ApiStatus from "../../components/ApiStatus";
import Heading from "../../components/Common/Heading"
import Section from "../../components/Common/Section"
import CardGroup from "../../components/Common/CardGroup"
import Card from "../../components/Common/Card"
import CardOverview from "../../components/Common/CardOverview";
import TabMenu from "../../components/Navigation/TabMenu"
import TabItem from "../../components/Navigation/TabItem"
import { formatDate } from "../../utils/DateFormatter";
import { IzobrazevanjePrijavaUporabnikGroupedType, useFetchUporabnikAktualno, useFetchUporabnikMojePrijave } from "./IzobrazevanjePrijavaHooks";
import { IzobrazevanjePrijavaListDto } from "../../types/IzobrazevanjePrijava/IzobrazevanjePrijavaListDto";
import { useNavigate } from "react-router-dom";
import Nullable from "../../types/Nullable";
import { format } from "date-fns";

interface AktualnoTabArgs {
    handleItemClick: (izobrazevanjeId: number) => void
}

const AktualnoTab = ({ handleItemClick }: AktualnoTabArgs) => {
    const { data, status, isSuccess } = useFetchUporabnikAktualno();

    if (!isSuccess)
        return <ApiStatus status={status}></ApiStatus>

    return (

        <>

            <Heading>
                <p>
                    Za vašo organizacijo so trenutno na voljo naslednja izobraževanja:
                </p>
            </Heading>
            {data && Object.keys(data).map((key) => (
                <CardGroup key={key} title={`${key}`}>
                    {
                        (data[key] as []).map((izobrazevanje: IzobrazevanjePrijavaListDto) => (
                            <Card
                                key={`${izobrazevanje.izobrazevanjeId}`}
                                onClick={() => { handleItemClick(izobrazevanje.izobrazevanjeId) }}
                                color='#397289'
                                icon="school"
                                title={`${izobrazevanje.naziv}`}
                                fromDate={format(new Date(izobrazevanje.datumCasOd), 'dd.MM.yyyy')}
                                toDate={format(new Date(izobrazevanje.datumCasDo), 'dd.MM.yyyy')}
                                showProstaMestaLabel={true}
                                steviloProstihMest={izobrazevanje.steviloProstihMest}
                                prijavaOmejena={izobrazevanje.prijavaOmejena}
                                stTock={izobrazevanje.tockeSt} />
                        ))}
                </CardGroup>
            ))}
        </>

    );
}

export default AktualnoTab;
