import { useContext } from "react";
import styled from "styled-components"
import { FeedbackContext, Status } from "../../context/FeedbackContext";
import Icon from "./Icon";

interface StyledFeedbackArgs {
    visible?: boolean,
    status?: Status
}

const StyledFeedback = styled.div<StyledFeedbackArgs>`
    transition: transform 0.25s cubic-bezier(0.83, 0, 0.17, 1);
    display: flex;
    align-items: center;
    span{
        margin-right: 1rem;
        margin-left: 0 ;
        font-size: 2.5rem;
    }
    z-index: 100;
    position: fixed;
    top: 4vh;
    left: 50%;
    border: 1px solid;
    font-weight: 600;
    padding: 0.5rem 1.5rem 0.5rem 1rem;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    ${props => props.visible ?
        'transform: translate(-50%, 0);' :
        'transform: translate(-50%, -250%);'
    };
    ${props => {
        if (props.status === Status.SUCCESS) {
            return `
                background-color: #e9ffee;
                span{
                    color: #477441;
                }
                color: #477441;
                border-color: #c4e6cc;
            `
        } else if (props.status === Status.ERROR) {
            return `
                background-color: #ffe4e6;
                span{
                    color: #82323a;
                }
                color: #82323a;
                border-color: #f7d5d8;
            `
        } else if (props.status === Status.WARNING) {
            return `
                background-color: #fff8e0;
                span{
                    color: #89690a;
                }
                color: #89690a;
                border-color: #ffefbe;
            `
        }
    }}
`

const Feedback = () => {
    const { feedback, visible, status } = useContext(FeedbackContext);

    return (
        <StyledFeedback status={status} visible={visible}>
            <Icon name={status === Status.SUCCESS ? 'check' : status === Status.ERROR ? 'close' : 'warning'}></Icon>
            {feedback}
        </StyledFeedback>
    )
}

export default Feedback