import { AxiosError } from 'axios';
import ApiException from '../types/ApiException';
import Nullable from '../types/Nullable';

const getApiExceptionObjectFromAxiosError = (error: Nullable<AxiosError>) => {
    let apiException: Nullable<ApiException> = null;
    if (!error)
        return apiException;

    if (error.response && error.response.data) {
        apiException = { ...(error.response.data as ApiException) };
    } else {
        apiException = { Message: error?.message, ValidationErrors: null };
    }

    return apiException;
}

export default getApiExceptionObjectFromAxiosError;