import { ParsedUrlQueryInput } from "querystring";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ApiStatus from "../../../components/ApiStatus";
import Button from "../../../components/Common/Button";
import Divider from "../../../components/Common/Divider";
import Heading from "../../../components/Common/Heading";
import usePagination from "../../../components/Common/PaginationHooks";
import Section from "../../../components/Common/Section";
import useSort from "../../../components/Common/SortingHooks";
import Table from "../../../components/Common/Table";
import TableHeader from "../../../components/Common/TableHeader";
import { IzobrazevanjeTipListType } from "../../../types/IzobrazevanjeTip/IzobrazevanjeTipListType";
import useFetchIzobrazevanjeTipAll from "../izobrazevanjeTip/IzobrazevanjeTipHooks";

const IzobrazevanjeTipList = () => {
    const { data, status, isSuccess } = useFetchIzobrazevanjeTipAll();

    const { setSort, sortedData, sortColumn, sortIcon } = useSort<IzobrazevanjeTipListType>(data);
    const { pageCount, paginate, setPaginate, currData, onPageChange } = usePagination<IzobrazevanjeTipListType>(sortedData);

    const TableHeaderProps = { sortColumn: sortColumn, onClickHandler: setSort, sortIcon: sortIcon };

    if (!isSuccess)
        return (
            <Section>
                <Heading>Pregled tipov izobraževanj
                    <Divider />
                </Heading>
                <ApiStatus status={status}></ApiStatus>
            </Section>
        )

    return (
        <Section>
            <Heading>Pregled tipov izobraževanj
                <Divider />
            </Heading>

            <Table
                pageCount={pageCount}
                onPageChange={onPageChange}
                paginate={paginate}
                setPaginate={setPaginate}
            >
                <thead>
                    <tr>
                        <TableHeader
                            {...TableHeaderProps}
                            indexName="naziv"
                            left
                        >Naziv</TableHeader>
                        <TableHeader
                            {...TableHeaderProps}
                            indexName="status"
                            center
                        >Status</TableHeader>
                        <TableHeader
                            {...TableHeaderProps}
                            indexName="stMestPrivzeto"
                            center
                        >Privzeto št. mest</TableHeader>
                        <TableHeader
                            {...TableHeaderProps}
                            indexName="stTockPrivzeto"
                            center
                        >Privzeto št. točk</TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {currData && currData.map((izobrazevanjeTip) => (
                        <tr key={izobrazevanjeTip.izobrazevanjeTipId}>
                            <td>
                                <Link to={`/sifrant/izobrazevanjeTip/edit/${izobrazevanjeTip.izobrazevanjeTipId}`}>
                                    {izobrazevanjeTip.naziv}
                                </Link>
                            </td>
                            <td className="txtCenter">{izobrazevanjeTip.statusNaziv}</td>
                            <td className="txtCenter">{izobrazevanjeTip.stMestPrivzeto ? izobrazevanjeTip.stMestPrivzeto : "/"}</td>
                            <td className="txtCenter">{izobrazevanjeTip.stTockPrivzeto ? izobrazevanjeTip.stTockPrivzeto : "/"}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Link to="/sifrant/izobrazevanjeTip/add">
                <Button startIcon="add">
                    dodaj
                </Button>
            </Link>
        </Section>
    );
}

export default IzobrazevanjeTipList;