import { format } from "date-fns";
import { IzobrazevanjeDetailType } from "../../types/Izobrazevanje/IzobrazevanjeDetailType";
import styled from "styled-components";
import timezoneShift from "../../utils/TimezoneShift";

const StyledIzobrazevanjeDetailForm = styled.div`
    h1, h3, p{
        font-weight: 350;
    }
    h1{
        font-size: 2rem;
        margin-top: 0;
        margin-bottom: 1rem;
    }
    h3{
        margin-top: 0;
        margin-bottom: 0.7rem;
    }
    p{
        margin-bottom: 0;
    }
    font-weight: 400;
    padding: 1.5rem;
    background-color: ${props => props.theme.colors.background};
    border: ${props => props.theme.colors.border} 1px solid;
    border-radius: 4px;
    width: 50%;
    /* margin-bottom: 1rem; */
    @media (max-width: 900px){
        width: 90%;
    }
`

type IzobrazevanjeDetailFormArgs = {
    izobrazevanje: IzobrazevanjeDetailType;
}

const IzobrazevanjeDetailForm = ({ izobrazevanje }: IzobrazevanjeDetailFormArgs) => {
    return (
        <StyledIzobrazevanjeDetailForm className="card">
            <h1>{`${izobrazevanje.naziv}`}</h1>
            <h3>Vrsta izobraževanja: <b>{`${izobrazevanje.izobrazevanjeTipNaziv}`}</b></h3>
            <h3>Predavatelj: <b>{izobrazevanje.predavatelj === null ? `/` : `${izobrazevanje.predavatelj}`} </b></h3>
            <h3>Trajanje: <b>{format(timezoneShift(new Date(izobrazevanje.datumCasOd)), 'dd.MM.yyyy, HH:mm')} - {format(timezoneShift(new Date(izobrazevanje.datumCasDo)), 'dd.MM.yyyy, HH:mm')}</b></h3>
            <h3>Na voljo za: 
                <b>{izobrazevanje.podrocja.map(item => ` ${item.naziv}`).join(`, `)}</b>
            </h3>
            <h3>Število točk: <b>{izobrazevanje.tockeSt == null ? `/` : `${izobrazevanje.tockeSt}`}</b></h3>
            {izobrazevanje.opis &&
                <p>
                    {`${izobrazevanje.opis}`}
                </p>}
        </StyledIzobrazevanjeDetailForm>
    );
}

export default IzobrazevanjeDetailForm;