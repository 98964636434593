import ApiException from "../../types/ApiException"

interface InputArgs {
    type?: string,
    placeholder?: string,
    value?: string | number,
    label: string,
    err: ApiException | null,
    errField: string,
    required?: boolean,
    isWholeNumber?: boolean,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    width?: string,
    min?: number,
    max?: number,
    disabled?: boolean
}

const Input = ({ type = 'text', placeholder, label, err, errField, required, onChange, value, width, min, max, isWholeNumber, disabled }: InputArgs) => {
    const onChangeWithValidate = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isWholeNumber && type === 'number') {
            e.target.value = e.target.value.replace(/\D/g, '');
        }
        onChange && onChange(e);
    }

    return (
        <>
            <label htmlFor={label}>
                {required ?
                    <span className="bold">
                        {label}:
                        <span className="red">*</span>
                    </span> :
                    label + ':'
                }
            </label>
            <input
                disabled={disabled}
                style={{ width: width }}
                placeholder={placeholder}
                value={value}
                type={type}
                min={min}
                max={max}
                step={1}
                onChange={isWholeNumber ? onChangeWithValidate : onChange} />

            {err && err.ValidationErrors && err.ValidationErrors[errField] &&
                <span className="error red">{err.ValidationErrors[errField]}</span>
            }

        </>
    )
}

export default Input