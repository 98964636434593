import styled from "styled-components"
import Icon from "../Common/Icon"
import { NavLink } from "react-router-dom"

interface StyledSidebarItemArgs {
    isSubitem?: boolean,
}

const StyledSidebarItem = styled.div<StyledSidebarItemArgs>`
    width: 100%;
    display: flex;
    padding: 1rem;
    font-size: 16px;
    align-items: center;
    box-sizing: border-box;
    color: white;
    border-bottom: 1px solid #88b6c9;
    text-transform: uppercase;
    background-color: inherit;
    user-select: none;
    span {
        transition: all 0.15s;
    }
    ${props => props.isSubitem && `
        padding-left: 1.5rem;
        font-size: 14px;
        background-color: inherit;
    `}
    &:hover{
        cursor: pointer;
        span:not(.rotatable){
            transform: translateX(2px);
        }
    }
    
`

interface SidebarItemArgs {
    target?: string
    isDropdown?: boolean
    isSubitem?: boolean
    icon: string
    children: React.ReactNode,
    handleClick?: () => void,
    close?: () => void,
    isExact?: boolean,
    className?: string,
    externalUrl?: boolean,
}

const SidebarItem = ({ target, className, isDropdown = false, isSubitem = false, icon, children, handleClick, close, isExact = false, externalUrl = false }: SidebarItemArgs) => {

    const handlePress = () => {
        if (handleClick) {
            handleClick()
        }
        if (close) {
            close()
        }
    }

    if (!isDropdown && target) {
        if (externalUrl) {
            return (
                <a className='item'
                    href={target}
                    // rel="noopener noreferrer"
                >
                    <StyledSidebarItem onClick={handlePress} isSubitem={isSubitem}>
                        <Icon name={icon} />
                        <span className={className}>
                            {children}
                        </span>
                        {isDropdown && <Icon className="rotatable" name='expand_more' right />}
                    </StyledSidebarItem>
                </a>
            )
        } else {
            return (
                <NavLink className='item' end={isExact}
                    to={target}
                    style={({ isActive }) => {
                        return {
                            backgroundColor: isActive ? '#e27f28' : isSubitem ? '#457081' : '',
                        }
                    }}
                >
                    <StyledSidebarItem onClick={handlePress} isSubitem={isSubitem}>
                        <Icon name={icon} />
                        <span className={className}>
                            {children}
                        </span>
                        {isDropdown && <Icon className="rotatable" name='expand_more' right />}
                    </StyledSidebarItem>
                </NavLink>
            )
        }
    } else {
        return (
            <StyledSidebarItem onClick={handlePress} isSubitem={isSubitem} >
                <Icon name={icon} />
                <span>
                    {children}
                </span>
                {isDropdown && <Icon name='expand_more' className="rotatable" right />}
            </StyledSidebarItem>
        )
    }
}

export default SidebarItem