import { format } from 'date-fns';
import Nullable from "../../types/Nullable";
import { IzobrazevanjePrijavaUporabnikType } from "../../types/Izobrazevanje/IzobrazevanjePrijavaUporabnikType";
import Table from '../../components/Common/Table';
import Button from '../../components/Common/Button';
import styled from 'styled-components';
import { useState } from 'react';
import Modal from '../../components/Common/Modal';

const StyledIzobrazevanjePrijaveList = styled.div`
    p {
        margin-top: 1rem;
    }
    .empty{
        margin-bottom: 1rem;
    }
    margin-bottom: 1rem;
`

interface IzobrazevanjePrijaveListArgs {
    izobrazevanjePrijave: Nullable<IzobrazevanjePrijavaUporabnikType[]>
    deactivated: () => void;
    exportCsv: () => void;
}

const IzobrazevanjePrijaveList = ({ izobrazevanjePrijave, deactivated, exportCsv}: IzobrazevanjePrijaveListArgs) => {

    const [showDeactivateModal, setShowDeactivateModal] = useState(false);

    const onDeactivate: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        e.preventDefault();
        deactivated();
    }

    const onExportCsv: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        e.preventDefault();
        exportCsv();
    }

    if (izobrazevanjePrijave?.length === 0) {
        return (
            <StyledIzobrazevanjePrijaveList>
                <Modal
                    visible={showDeactivateModal}
                    message="Ali ste prepričani da želite deaktivirati to izobraževanje?"
                    actionMessage="Deaktiviraj"
                    actionIcon='toggle off'
                    onCancel={() => setShowDeactivateModal(false)}
                    onConfirm={onDeactivate}
                />
                <div className='empty'>Ni prijav za to izobraževanje.</div>
                <Button onClick={() => setShowDeactivateModal(true)} tertiary>Deaktiviraj</Button>
            </StyledIzobrazevanjePrijaveList>
        )
    }

    return (
        <StyledIzobrazevanjePrijaveList>
            <Table>
                <thead>
                    <tr >
                        <th className="txtLeft">Ime</th>
                        <th className="txtLeft">Priimek</th>
                        <th className="txtLeft" style={{ width: '25%' }}>E-mail</th>
                        <th>Datum prijave</th>
                    </tr>
                </thead>
                <tbody>
                    {izobrazevanjePrijave && izobrazevanjePrijave.map((prijava) => (
                        <tr key={prijava.izobrazevanjePrijavaId}>
                            <td className="txtLeft">{prijava.ime}</td>
                            <td className="txtLeft">{prijava.priimek}</td>
                            <td className="txtLeft">{prijava.email}</td>
                            <td className="txtCenter">{format(new Date(prijava.datumPrijave), 'dd.MM.yyyy')}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Button startIcon='download' onClick={onExportCsv}>izvozi v Excel</Button>
        </StyledIzobrazevanjePrijaveList>
    );
}

export default IzobrazevanjePrijaveList;
