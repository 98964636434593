import Divider from "../../components/Common/Divider";
import Heading from "../../components/Common/Heading";
import Section from "../../components/Common/Section";

const NotLoggedIn = () => {
    return (
        <Section>
            <Heading>Napaka
                <Divider />
            </Heading>
            <p>
                Not logged in (glej task #1795)
            </p>
        </Section>
    )
}

export default NotLoggedIn;