import ApiStatus from "../../components/ApiStatus";
import Heading from "../../components/Common/Heading"
import CardGroup from "../../components/Common/CardGroup"
import Card from "../../components/Common/Card"
import { useFetchUporabnikZakljuceno } from "./IzobrazevanjePrijavaHooks";
import { IzobrazevanjePrijavaListDto } from "../../types/IzobrazevanjePrijava/IzobrazevanjePrijavaListDto";
import { format } from "date-fns";


interface ZakljucenoTabArgs {
    handleItemClick: (izobrazevanjePrijavaId: number) => void
}

const ZakljucenoTab = ({ handleItemClick }: ZakljucenoTabArgs) => {
    const { data, status, isSuccess } = useFetchUporabnikZakljuceno();

    if (!isSuccess)
        return <ApiStatus status={status}></ApiStatus>

    return (

        <>
            <Heading>
                <p>
                    Vaša zaključena izobraževanja:
                </p>
            </Heading>
            {data && Object.keys(data).map((key) => (
                <CardGroup key={key} title={`${key}`}>
                    {
                        (data[key] as []).map((izobrazevanje: IzobrazevanjePrijavaListDto) => (
                            <Card
                                key={`${izobrazevanje.izobrazevanjePrijavaId}`}
                                onClick={() => { handleItemClick(izobrazevanje.izobrazevanjeId) }}
                                color='#397289'
                                icon="school"
                                title={`${izobrazevanje.naziv}`}
                                fromDate={format(new Date(izobrazevanje.datumCasOd), 'dd.MM.yyyy')}
                                toDate={format(new Date(izobrazevanje.datumCasDo), 'dd.MM.yyyy')}
                                showProstaMestaLabel={false}
                                stTock={izobrazevanje.tockeSt} />
                        ))}
                </CardGroup>
            ))}
        </>



    );
}

export default ZakljucenoTab;
