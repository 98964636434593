import format from "date-fns/format";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFilteredData } from "react-widgets/esm/Filter";
import Button from "../../components/Common/Button";
import Divider from "../../components/Common/Divider";
import Heading from "../../components/Common/Heading";
import usePagination from "../../components/Common/PaginationHooks";
import Section from "../../components/Common/Section";
import useSort from "../../components/Common/SortingHooks";
import Table from "../../components/Common/Table";
import TableHeader from "../../components/Common/TableHeader";
import FilterItem from "../../components/Filter/FilterItem";
import FilterItems from "../../components/Filter/FilterItems";
import DropdownInput from "../../components/Form/DropdownInput";
import TextInput from "../../components/Form/TextInput";
import { IzobrazevanjeListType } from "../../types/Izobrazevanje/IzobrazevanjeListType";
import { IzobrazevanjeStatus } from "../../utils/Enums";
import timezoneShift from "../../utils/TimezoneShift";
import useFetchIzobrazevanjeTipAll from "../sifrant/izobrazevanjeTip/IzobrazevanjeTipHooks";
import IzobrazevanjeListTable from "./IzobrazevanjeListTable";

type IzobrazevanjeListTableFiltriArgs = {
    izobrazevanjeListData: IzobrazevanjeListType[];
}

const IzobrazevanjeListTableFiltri = ({ izobrazevanjeListData }: IzobrazevanjeListTableFiltriArgs) => {
    const { data: FilterIzobrazevanjeTipi } = useFetchIzobrazevanjeTipAll();
    const [listData, setListData] = useState({ allData: izobrazevanjeListData, filteredData: izobrazevanjeListData });
    const [filterNaziv, setFilterNaziv] = useState("");
    const [filterTip, setFilterTip] = useState(-1);
    const [filterStatus, setFilterStatus] = useState(-1);

    function filterDataIzobrazevanjeNaziv(e: React.ChangeEvent<HTMLInputElement>) {
        // if (e.target.value === "" || e.target.value.length < 3)
        //     setFilterNaziv(""); 
        // else if (e.target.value.length > 2)

        setFilterNaziv(e.target.value);
    }

    function filterDataIzobrazevanjeTip(e: React.ChangeEvent<HTMLSelectElement>) {
        setFilterTip(Number(e.target.value));
    }

    function filterDataIzobrazevanjeStatus(e: React.ChangeEvent<HTMLSelectElement>) {
        setFilterStatus(Number(e.target.value));
    }

    //TODO: useFilteredData - mogoče za pogledat?
  
    useEffect(() => {

        setListData({ ...listData, filteredData: listData.allData
            .filter(ld =>
                (ld.naziv.toLowerCase().includes(filterNaziv.toLowerCase()) || filterNaziv === "")
                && (ld.izobrazevanjeTipId === Number(filterTip) || filterTip === -1)
                && (ld.status === Number(filterStatus) || filterStatus === -1)
                )});
    }, [filterNaziv, filterTip, filterStatus])

    return (
        <>
            <FilterItems>
                <FilterItem>
                    <TextInput
                        label="Poišči"
                        type="text"
                        width="calc(8vw + 9rem)"
                        placeholder="Naziv"
                        err={null}
                        errField="Naziv"
                        onChange={(e) => filterDataIzobrazevanjeNaziv(e)}
                    />
                </FilterItem>
                <FilterItem>
                    <DropdownInput 
                        label="Tip"
                        width="180px"
                        value={filterTip}
                        onChange={(e) => filterDataIzobrazevanjeTip(e)}
                    >
                        <option key={-1} value={-1}>vsi tipi</option>
                        {FilterIzobrazevanjeTipi && FilterIzobrazevanjeTipi.map((it) => (
                            <option key={it.izobrazevanjeTipId} value={it.izobrazevanjeTipId}>{it.naziv}</option>
                        ))}
                    </DropdownInput>
                </FilterItem>
                <FilterItem>
                    <DropdownInput
                        label="Status"
                        width="180px"
                        value={filterStatus}
                        onChange={(e) => filterDataIzobrazevanjeStatus(e)}
                    >
                        <option key={-1} value={-1}>vsi statusi</option>
                        <option key={IzobrazevanjeStatus.VPripravi} value={IzobrazevanjeStatus.VPripravi}>v pripravi</option>
                        <option key={IzobrazevanjeStatus.Objavljeno} value={IzobrazevanjeStatus.Objavljeno}>objavljeno</option>
                    </DropdownInput>
                </FilterItem>
            </FilterItems>
            <IzobrazevanjeListTable 
                izobrazevanjeData={listData.filteredData} 
            />
        </>
    );
}

export default IzobrazevanjeListTableFiltri;