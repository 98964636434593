import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import ApiStatus from "../../components/ApiStatus";
import Button from "../../components/Common/Button";
import Divider from "../../components/Common/Divider";
import Heading from "../../components/Common/Heading";
import Section from "../../components/Common/Section";
import getApiExceptionObjectFromAxiosError from "../../utils/ProcessException";
import { useFetchPrijavaByIzobrazevanjeId, useFetchPrijavaByIzobrazevanjeIdCsv } from "../prijava/IzobrazevanjePrijavaHooks";
import IzobrazevanjeDetailForm from "./IzobrazevanjeDetailForm";
import { useDeactivateIzobrazevanje, useFetchIzobrazevanjeDetail } from "./IzobrazevanjeHooks";
import IzobrazevanjePrijaveList from "./IzobrazevanjePrijaveList";
import styled from "styled-components";

const StyledIzobrazevanjeDetailForm = styled.div`
    p{
        margin: 0 0 1rem 0;
    }
    .upper{
        display: flex;
        gap: 2rem;
    }
    .right {
        width: 30%;
    }
    .heading {
        margin-top: 2rem;
        margin-bottom: 1rem;
        font-size: 1.7rem;
    }
    .available{
        margin-bottom: 1rem;
        font-weight: 500;
    }
    .card{
        margin-bottom: 1rem;
    }
    @media (max-width: 650px) {
        .upper{
            flex-direction: column;
            gap: 0;
        }
        .right {
            width: 100%;
            margin-bottom: 1rem;
        }
    }
`

const IzobrazevanjeDetail = () => {
    const nav = useNavigate();
    const { izobrazevanjeId } = useParams();
    if (!izobrazevanjeId)
        throw Error("Manjka parameter izobrazevanjeId");

    const izobrazevanjeIdInt = parseInt(izobrazevanjeId);

    const { data: izobrazevanjeData, status: izobrazevanjeStatus, isSuccess: izobrazevanjeIsSuccess, error: izobrazevanjeError } = useFetchIzobrazevanjeDetail(izobrazevanjeIdInt);
    const { data: prijaveData, status: prijaveStatus, isSuccess: prijaveIsSuccess, error: prijaveError } = useFetchPrijavaByIzobrazevanjeId(izobrazevanjeIdInt);

    const deactivateIzobrazevanjeMutatied = useDeactivateIzobrazevanje();
    const { refetch } = useFetchPrijavaByIzobrazevanjeIdCsv(izobrazevanjeIdInt);

    function izracunProstihMest(): number {
        let ret = 0;
        if (izobrazevanjeData && izobrazevanjeData.prijavaOmejena && izobrazevanjeData.udelezenciSt) {
            ret = (izobrazevanjeData.udelezenciSt - izobrazevanjeData.steviloPrijav);
        }
        return ret;
    }

    if (izobrazevanjeError) {
        return (
            <Section>
                <Heading>Podrobnosti izobraževanja
                    <Divider />
                </Heading>
                <ApiStatus status={izobrazevanjeStatus}></ApiStatus>
                <div>
                    <span>Prišlo je do napake pri pridobivanju podatkov za izobraževanje.</span>
                    <span className="error">
                        <p className="red">{getApiExceptionObjectFromAxiosError(izobrazevanjeError)?.Message}</p>
                    </span>
                </div>
            </Section>
        )
    }

    return (
        <Section>
            <Heading>Podrobnosti izobraževanja
                <Divider />
            </Heading>
            {izobrazevanjeData &&
                <StyledIzobrazevanjeDetailForm>
                    <div className="upper">
                        <IzobrazevanjeDetailForm izobrazevanje={izobrazevanjeData}></IzobrazevanjeDetailForm>
                        {izobrazevanjeData.povezava &&
                            <div className="right">
                                <p>Izobraževanje je dostopno na spodnji povezavi:</p>
                                <a href={izobrazevanjeData.povezava} target="_blank" rel="noreferrer">
                                    <Button>pojdi na izobraževanje</Button>
                                </a>
                            </div>
                        }
                    </div>
                    <Button startIcon="arrow_back_ios" onClick={() => nav('/izobrazevanje/list')}>
                        Nazaj na seznam
                    </Button>
                    <Heading>Pregled prijav</Heading>
                    {prijaveError &&
                        <>
                            <ApiStatus status={prijaveStatus}></ApiStatus>
                            <div>
                                <span>Prišlo je do napake pri pridobivanju podatkov za prijave.</span>
                                <span className="error">
                                    <p className="red">{getApiExceptionObjectFromAxiosError(prijaveError)?.Message}</p>
                                </span>
                            </div>
                        </>
                    }
                    {izobrazevanjeData.steviloPrijav > 0 &&
                        <div className="available">Število prijav: {izobrazevanjeData.steviloPrijav}</div>
                    }
                    {izobrazevanjeData.prijavaOmejena &&
                        <div className="available">Število prostih mest: {izracunProstihMest()} / {izobrazevanjeData.udelezenciSt}</div>
                    }
                    <IzobrazevanjePrijaveList
                        izobrazevanjePrijave={prijaveData}
                        deactivated={() => deactivateIzobrazevanjeMutatied.mutate(izobrazevanjeIdInt)}
                        exportCsv={() => refetch()}
                    />
                </StyledIzobrazevanjeDetailForm>
            }
        </Section>
    );
}

export default IzobrazevanjeDetail;