import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation } from '@tanstack/react-query';
import AppConfig from "../../AppConfig";
import { useContext } from "react";
import { AuthContext, AuthContextProviderProps } from "../../context/AuthContext";
import Nullable from "../../types/Nullable";
import { Uporabnik } from "../../types/Uporabnik";

const useAddUporabnik = (setLoggedInUserParam: ((uporabnik: Nullable<Uporabnik>) => void) | null) => {
    let { setLoggedInUser } = useContext<AuthContextProviderProps>(AuthContext);
    return useMutation<AxiosResponse, AxiosError, string>(
        (accessToken) => axios.post(`${AppConfig.baseApiUrl}/uporabnik`, null,
            {
                headers: { Authorization: `Bearer ${accessToken}` }
            }
        ),
        {
            onSuccess: (resp) => {
                if (!setLoggedInUser && setLoggedInUserParam)
                    setLoggedInUser = setLoggedInUserParam;
                setLoggedInUser!(resp.data);
            }
        }
    );
};

export default useAddUporabnik;