import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import ReactDOM from 'react-dom/client';
// import { QueryClient, QueryClientProvider } from 'react-query';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { MsalConfig } from "./AppConfig";
import App from './App';
import 'material-symbols';

const queryClient = new QueryClient();
const msalInstance = new PublicClientApplication(MsalConfig);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
     <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  </React.StrictMode>
);

