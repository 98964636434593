import styled from 'styled-components';

const StyledFilterBox = styled.div`

    padding: 0.8rem 1rem;
    background-color: ${props => props.theme.colors.background};
    border: ${props => props.theme.colors.border} 1px solid;
    border-radius: 4px;
    width: fit-content;
    margin-bottom: 2rem;

`

interface Props {
    children: React.ReactNode;
}

const FilterBox = ({ children }: Props) => {
    return (
        <div style={{ overflowX: 'auto' }}>
            <StyledFilterBox>{children}</StyledFilterBox>
        </div>
    )
}

export default FilterBox