import styled from 'styled-components'
import SidebarItem from './SidebarItem'
import SidebarGroup from './SidebarGroup'
import Icon from '../Common/Icon'
import { Link } from 'react-router-dom'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
import { AuthContext } from '../../context/AuthContext'
import Button from '../Common/Button'
import { LoginRequest } from '../../AppConfig'

interface StyledSidebarArgs {
    active: boolean
}

const StyledSidebar = styled.section<StyledSidebarArgs>`
    grid-area: sidebar;
    background-color: ${props => props.theme.colors.secondary};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    font-size: 12px;
    position: relative;
    transition: transform 0.25s cubic-bezier(0.83, 0, 0.17, 1);
    .right{
        display: flex;
        align-items: center;
        span{
            margin-right: 0.7rem;
        }
    }
    a{
        transition: all 0.15s;
    }
    .right a{
        font-size: 0.9rem;
        p{
            margin: 0;
        }
        display: flex;
        align-items: center; 
        margin-right: 2rem;
    }
    .utility{
        padding: 1rem;
        display: flex;
        height: fit-content;
        justify-content: space-between;
        border-bottom: 1px solid #88b6c9;
        background-color: ${props => props.theme.colors.primary};
    }
    .footer{
        margin: 0 0 0.7rem 1rem;
    }
    .item:after{
        opacity: 0;
        transition: all .2s ease-out;
        content: "";
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        right: -12px;
        transform: translateY(-32px);
        margin-top: -10px;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 12px solid ${props => props.theme.colors.tertiary};
    }
    .item.active:after {
        opacity: 1;
    }
    @media (max-width: 1000px) {
        .active:after{
            display: none;
        }
        transform: translateX(-101%);
    }
    @media (min-width: 1000px) {
        display: flex;
        transform: translateX(0);
        .utility{
            display: none;
        }
    }
    ${props => props.active ? `
        transform: translateX(0) !important;
    ` : `
        transform: translateX(-101%);
    ` };
`

interface SidebarArgs {
    active: boolean,
    onClick: () => void
}

const Sidebar = ({ active, onClick }: SidebarArgs) => {
    const { instance } = useMsal();

    const handleLogin: React.MouseEventHandler<HTMLButtonElement> = () => {
        instance.loginRedirect(LoginRequest);
    }

    const handleLogout: React.MouseEventHandler<HTMLButtonElement> = () => {
        instance.logoutRedirect({ postLogoutRedirectUri: "/" });
    }

    return (
        <StyledSidebar active={active} id='sidebar'>
            <div className='menu'>
                <div className='utility'>
                    <Icon onClick={onClick} name='close' />
                    <div className='right'>
                        <AuthContext.Consumer>
                            {({ loggedInUser }) => (
                                <Link to='/'>
                                    <Icon name='person' onClick={onClick} />
                                    <p>{loggedInUser?.ime} {loggedInUser?.priimek}</p>
                                </Link>
                            )}
                        </AuthContext.Consumer>
                        <AuthenticatedTemplate>
                            <Button className='log' tertiary onClick={handleLogout}>Odjava</Button>
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                            <Button className='log' tertiary onClick={handleLogin}>Prijava</Button>
                        </UnauthenticatedTemplate>
                    </div>
                </div>
                <AuthenticatedTemplate>
                    <SidebarItem isExact close={onClick} target='/' icon='home'>Izobraževanja</SidebarItem>
                    <AuthContext.Consumer>
                        {({ loggedInUser }) => (
                            <>
                                {loggedInUser?.isAdministrator && (
                                    <SidebarGroup icon="settings" name="admin">
                                        <SidebarItem close={onClick} target='/izobrazevanje/list' icon='view_list' isSubitem>Pregled</SidebarItem>
                                        <SidebarItem close={onClick} target='/sifrant/izobrazevanjeTip' icon='import_contacts' isSubitem>Tipi izobraževanj</SidebarItem>
                                        <SidebarItem close={onClick} target='/sifrant/organizacija' icon='group' isSubitem>Organizacije</SidebarItem>
                                    </SidebarGroup>)}
                            </>
                        )
                        }
                    </AuthContext.Consumer>
                </AuthenticatedTemplate>
                <SidebarItem externalUrl close={onClick} target='https://mddsz.e-ucenje.com/' icon='exit_to_app'>Vrni se</SidebarItem>
            </div>
            <div className='footer'>
                Verzija 1.0 <br />
                B2 IT d.o.o.
            </div>
        </StyledSidebar>
    )
}

export default Sidebar