import Section from "../../../components/Common/Section";
import { IzobrazevanjeTipFormType } from "../../../types/IzobrazevanjeTip/IzobrazevanjeTipFormType";
import getApiExceptionObjectFromAxiosError from "../../../utils/ProcessException";
import IzobrazevanjeTipForm from "./IzobrazevanjeTipForm";
import { useAddIzobrazevanjeTip } from "./IzobrazevanjeTipHooks";

const IzobrazevanjeTipAdd = () => {
    const addIzobrazevanjeTipMutated = useAddIzobrazevanjeTip();

    const izobrazevanjeTip: IzobrazevanjeTipFormType = {
        izobrazevanjeTipId: 0,
        naziv: "",
        stMestPrivzeto: null,
        stTockPrivzeto: null
        //status: 1
    };

    return (
        <Section>
            <IzobrazevanjeTipForm
                izobrazevanjeTip={izobrazevanjeTip}
                submitted={(dm) => addIzobrazevanjeTipMutated.mutate(dm)}
                apiException={getApiExceptionObjectFromAxiosError(addIzobrazevanjeTipMutated?.error)}
            />
        </Section>
    );
}

export default IzobrazevanjeTipAdd;