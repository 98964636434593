import styled from 'styled-components'

const StyledHeading = styled.div`
    
    font-size: 32px;
    font-weight: 300;
    margin-bottom: 2rem;
    width: 100%;

    p{
        font-size: 14px;
        font-weight: 400;
    }

    .red {
        color: red;
    }

`

interface HeadingArgs {
    children: React.ReactNode
}

const Heading = ({ children }: HeadingArgs) => {
    return (
        <StyledHeading className='heading'>{children}</StyledHeading>
    )
}

export default Heading