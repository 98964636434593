import Icon from "./Icon";

interface TableHeaderProps {
    sortColumn: string | null;
    indexName: string,
    onClickHandler: (indexName: string) => void,
    sortIcon: string,
    left?: boolean,
    center?: boolean,
    children?: React.ReactNode
    width?: string
}

const TableHeader = ({ indexName, onClickHandler, sortColumn, sortIcon, left, center, children, width }: TableHeaderProps) => {
    return (
        <th style={{ width: width }} className={left ? 'txtLeft' : center ? 'txtCenter' : ''} onClick={() => onClickHandler(indexName)}>
            {children}
            {sortColumn === indexName && <Icon name={sortIcon} />}
        </th>
    )
}

export default TableHeader