import React, { createContext, useState } from "react";
import Nullable from "../types/Nullable";
import { Uporabnik } from "../types/Uporabnik";
import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { AccessTokenRequest } from "../AppConfig";
import useAddUporabnik from "../pages/login/LoginHooks";
import { useIsAuthenticated } from "@azure/msal-react";

export interface AuthContextProviderProps {
    loggedInUser?: Nullable<Uporabnik>;
    setLoggedInUser?: (uporabnik: Nullable<Uporabnik>) => void;
}

export const AuthContext = createContext<AuthContextProviderProps>({});

type AuthProviderArgs = {
    children?: React.ReactNode;
}

const AuthProvider = ({ children } : AuthProviderArgs) => {
    const [loggedInUser, setLoggedInUser] = useState<Nullable<Uporabnik>>();
    const { instance, accounts } = useMsal();
    const addUporabnikMutatied = useAddUporabnik(setLoggedInUser);
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (isAuthenticated) {
            const request = {
                ...AccessTokenRequest,
                account: accounts[0]
            };

            // Silently acquires an access token which is then attached to a request for API data
            instance.acquireTokenSilent(request)
                .then((response) => addUporabnikMutatied.mutate(response.accessToken))
                .catch(() => instance.acquireTokenRedirect(request));
        }
    }, []);

    return (
        <AuthContext.Provider value={
            {loggedInUser, setLoggedInUser}
        }>
            {children}
        </AuthContext.Provider>
    );
}

export { AuthProvider }