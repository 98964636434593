import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './components/Common/Layout';
import GlobalStyle from './global.style';
import IzobrazevanjeList from './pages/izobrazevanje/IzobrazevanjeList';
import IzobrazevanjeAdd from './pages/izobrazevanje/IzobrazevanjeAdd';
import IzobrazevanjeDetail from './pages/izobrazevanje/IzobrazevanjeDetail';
import IzobrazevanjeEdit from './pages/izobrazevanje/IzobrazevanjeEdit';
import PrijavaDetail from './pages/prijava/PrijavaDetail';
import Login from './pages/login/Login';
import SessionEnd from './pages/login/SessionEnd';
import IzobrazevanjeTipEdit from './pages/sifrant/izobrazevanjeTip/IzobrazevanjeTipEdit';
import IzobrazevanjeTipList from './pages/sifrant/izobrazevanjeTip/IzobrazevanjeTipList';
import IzobrazevanjeTipAdd from './pages/sifrant/izobrazevanjeTip/izobrazevanjeTipAdd';
import { AuthProvider } from './context/AuthContext';
import RequiredAuth from './components/Auth/RequireAuth';
import NotLoggedIn from './pages/login/NotLoggedIn';
import Unauthorized from './pages/login/Unauthorized';
import { UserRole } from './utils/Enums';
import PrijavaList from './pages/prijava/PrijavaList';
import { ThemeProvider } from 'styled-components';
import Topbar from './components/Navigation/Topbar';
import Sidebar from './components/Navigation/Sidebar';
import { useState } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { LoginRequest } from './AppConfig';
import LoginPage from './pages/login/LoginPage';
import { FeedbackProvider } from './context/FeedbackContext';
import Feedback from './components/Common/Feedback';
import PodrocjeList from './pages/sifrant/podrocje/PodrocjeList';
import PodrocjeAdd from './pages/sifrant/podrocje/PodrocjeAdd';
import PodrocjeEdit from './pages/sifrant/podrocje/PodrocjeEdit';

const theme = {
  colors: {
    primary: '#397289',
    secondary: '#6392a6',
    tertiary: '#e27f28',
    background: '#f5f5f5',
    cancel: '#a8a8a8',
    border: '#c3c3c3',
  }
}

function App() {

  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

  const handleSidebarOpen = () => {
    setIsSidebarOpen((prevState) => !prevState);
  }

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Layout>
          <GlobalStyle />
          <AuthProvider>
            <FeedbackProvider>
              <AuthenticatedTemplate>
                <Feedback />
                <Topbar hamburgerClick={handleSidebarOpen} />
                <Sidebar onClick={handleSidebarOpen} active={isSidebarOpen} />
                <Routes>
                  <Route element={<RequiredAuth />}>
                    <Route path="/" element={<PrijavaList />}></Route> {/* Prijava - zavihki/seznam */}
                    {/* <Route path="/prijava/:prijavaId" element={<PrijavaDetail />}></Route> */} {/* Prijava - prijava/pregled */}
                  </Route>

                  <Route element={<RequiredAuth allowedRols={[UserRole.Administrator]} />}>
                    <Route path="/izobrazevanje" element={<IzobrazevanjeList />}></Route> {/*izobraževanje - seznam (admin)*/}
                    <Route path="/izobrazevanje/list" element={<IzobrazevanjeList />}></Route> {/*izobraževanje - seznam (admin)*/}
                    <Route path="/izobrazevanje/add" element={<IzobrazevanjeAdd />}></Route> {/*izobraževanje - dodaj (admin)*/}
                    <Route path="/izobrazevanje/edit/:izobrazevanjeId" element={<IzobrazevanjeEdit />}></Route> {/*izobraževanje - uredi (admin)*/}
                    <Route path="/izobrazevanje/:izobrazevanjeId" element={<IzobrazevanjeDetail />}></Route> {/*izobraževanje - pregled (admin)*/}

                    <Route path="/sifrant/organizacija" element={<PodrocjeList />}></Route> {/*šifrant - organizacije - seznam (admin)*/}
                    <Route path="/sifrant/organizacija/add" element={<PodrocjeAdd />}></Route> {/*šifrant - organizacije - dodaj (admin)*/}
                    <Route path="/sifrant/organizacija/edit/:organizacijaId" element={<PodrocjeEdit />}></Route> {/*šifrant - organizacije - uredi (admin)*/}

                    <Route path="/sifrant/izobrazevanjeTip" element={<IzobrazevanjeTipList />}></Route> {/*šifrant - tip izobraževanja - seznam (admin)*/}
                    <Route path="/sifrant/izobrazevanjeTip/add" element={<IzobrazevanjeTipAdd />}></Route> {/*šifrant - tip izobraževanja - dodaj (admin)*/}
                    <Route path="/sifrant/izobrazevanjeTip/edit/:izobrazevanjeTipId" element={<IzobrazevanjeTipEdit />}></Route> {/*šifrant - tip izobraževanja - uredi (admin)*/}
                  </Route>

                  <Route path="/notloggedin" element={<NotLoggedIn />}></Route>
                  <Route path="/unauthorized" element={<Unauthorized />}></Route>
                  <Route path="/login" element={<Login />}></Route>
                  <Route path="/session/end" element={<SessionEnd />}></Route>
                </Routes>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <LoginPage />
              </UnauthenticatedTemplate>
            </FeedbackProvider>
          </AuthProvider>
        </Layout>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;