import styled from 'styled-components'

const StyledFormActions = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    div{
        display: flex;
    }

    .right{
        button{
            margin-left: 1rem;
        }
    }

    @media (max-width: 400px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        button {
            margin-left: 0 !important;
            margin-right: 1rem;
        }
    }
`

interface FormActionArgs {
    children: React.ReactNode
}

const FormActions = ({ children }: FormActionArgs) => {
    return (
        <StyledFormActions>
            {children}
        </StyledFormActions>
    )
}

export default FormActions