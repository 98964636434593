interface DropdownInputArgs {
    value?: string | number,
    label: string,
    err?: string[] | null,
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void,
    width?: string,
    required?: boolean
    children: React.ReactNode
}

const DropdownInput = ({ label, err, onChange, value, width, children, required }: DropdownInputArgs) => {
    return (
        <>
            <label htmlFor={label}>
                {required ?
                    <span className="bold">
                        {label}:
                        <span className="red">*</span>
                    </span> :
                    label + ':'
                }
            </label>
            <select
                required={required}
                style={{ width: 'fit-content' }}
                value={value}
                onChange={onChange && function (e) { onChange(e) }}
            >
                {children}
            </select>

            {err &&
                <span className="error red">{err}</span>
            }

        </>
    )
}

export default DropdownInput