import format from "date-fns/format";
import { Link } from "react-router-dom";
import Button from "../../components/Common/Button";
import Divider from "../../components/Common/Divider";
import Heading from "../../components/Common/Heading";
import usePagination from "../../components/Common/PaginationHooks";
import Section from "../../components/Common/Section";
import useSort from "../../components/Common/SortingHooks";
import Table from "../../components/Common/Table";
import TableHeader from "../../components/Common/TableHeader";
import { IzobrazevanjeListType } from "../../types/Izobrazevanje/IzobrazevanjeListType";
import { IzobrazevanjeStatus } from "../../utils/Enums";
import timezoneShift from "../../utils/TimezoneShift";

type IzobrazevanjeListTableArgs = {
    izobrazevanjeData: IzobrazevanjeListType[];
}

const IzobrazevanjeListTable = ({ izobrazevanjeData }: IzobrazevanjeListTableArgs) => {

    const { setSort, sortedData, sortColumn, sortIcon } = useSort<IzobrazevanjeListType>(izobrazevanjeData);
    const { pageCount, paginate, setPaginate, currData, onPageChange } = usePagination<IzobrazevanjeListType>(sortedData);
    const TableHeaderProps = { sortColumn: sortColumn, onClickHandler: setSort, sortIcon: sortIcon };

    if (izobrazevanjeData.length <= 0) {
        return(<div>Ni podatkov za prikaz</div>);
    }

    return (
        <>   
            <div> 
                <Table
                    fullWidth
                    pageCount={pageCount}
                    onPageChange={onPageChange}
                    paginate={paginate}
                    setPaginate={setPaginate}
                >
                    <thead>
                        <tr>
                            <TableHeader {...TableHeaderProps}
                                indexName="naziv"
                                left
                            >Naziv</TableHeader>
                            <TableHeader {...TableHeaderProps}
                                indexName="izobrazevanjeTipNaziv"
                                center
                            >Tip</TableHeader>
                            <TableHeader {...TableHeaderProps}
                                indexName="tockeSt"
                                center
                            >Št. točk</TableHeader>
                            <TableHeader {...TableHeaderProps}
                                indexName="datumCasOd"
                                center
                            >Datum od</TableHeader>
                            <TableHeader {...TableHeaderProps}
                                indexName="datumCasDo"
                                center
                            >Datum do</TableHeader>
                            <TableHeader {...TableHeaderProps}
                                width="12%"
                                center
                                indexName="statusNaziv"
                            >Status</TableHeader>
                            <TableHeader {...TableHeaderProps}
                                indexName="udelezenciSt"
                                center
                            >Št. mest</TableHeader>
                            <TableHeader {...TableHeaderProps}
                                indexName="prijaveSt"
                                center
                            >Št. prijav</TableHeader>
                        </tr>
                    </thead>
                    <tbody>
                        {currData && currData.map((izobrazevanje) => (
                            <tr key={izobrazevanje.izobrazevanjeId}>
                                <td className="txtLeft">
                                    <Link to={izobrazevanje.status == IzobrazevanjeStatus.Objavljeno ? `/izobrazevanje/${izobrazevanje.izobrazevanjeId}` : `/izobrazevanje/edit/${izobrazevanje.izobrazevanjeId}`}>
                                        {izobrazevanje.naziv}
                                    </Link>
                                </td>
                                <td className="txtCenter">{izobrazevanje.izobrazevanjeTipNaziv}</td>
                                <td className="txtCenter">{izobrazevanje.tockeSt ? izobrazevanje.tockeSt : "/"}</td>
                                <td className="txtCenter">{format(timezoneShift(new Date(izobrazevanje.datumCasOd)), 'dd.MM.yyyy')}</td>
                                <td className="txtCenter">{format(timezoneShift(new Date(izobrazevanje.datumCasDo)), 'dd.MM.yyyy')}</td>
                                <td className="txtCenter">{izobrazevanje.statusNaziv}</td>
                                <td className="txtCenter">{izobrazevanje.prijavaOmejena ? izobrazevanje.udelezenciSt : `neomejeno`}</td>
                                <td className="txtCenter">{izobrazevanje.prijaveSt}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div> 
            <Link to="/izobrazevanje/add">
                <Button startIcon="add">
                    dodaj
                </Button>
            </Link>
        </>
    );
}

export default IzobrazevanjeListTable;