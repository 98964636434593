import styled from "styled-components"

const StyledInput = styled.input`
    display: inline;
    width: fit-content;
`

interface CheckboxItemProps {
    checked?: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string;
    value: string | number;
    name: string;
    id?: string;
}

const CheckboxItem = ({ checked, onChange, label, value, name, id }: CheckboxItemProps) => {
    return (
        <>
            <StyledInput
                value={value}
                name={name}
                id={id}
                type="checkbox"
                checked={checked}
                onChange={onChange}
            />
            <label style={{ width: 'auto' }} htmlFor={name}>{label}</label>
            <br />
        </>
    )
}

export default CheckboxItem