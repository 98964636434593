type Args = {
  status: "idle" | "success" | "error" | "loading";
};

const ApiStatus = ({ status }: Args) => {
  switch (status) {
    case "error":
      return <div>Napaka pri branju podatkov</div>;
    case "idle":
      return <div>Idle</div>;
    case "loading":
      return <div>Nalagam...</div>;
    case "success":
      return <div>Zaključeno</div>;
    default:
      throw Error(`Neznano API stanje: ${status}`);
  }
};

export default ApiStatus;
