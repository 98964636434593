import { useNavigate, useParams } from "react-router-dom";
import Divider from "../../components/Common/Divider";
import Heading from "../../components/Common/Heading";
import Section from "../../components/Common/Section";
import IzobrazevanjeForm from "./IzobrazevanjeForm";
import ApiStatus from "../../components/ApiStatus";
import { useFetchIzobrazevanjeWithSifranti, useUpdateIzobrazevanjeDraft, useUpdateIzobrazevanjePublish, useDeleteIzobrazevanje } from "./IzobrazevanjeHooks";
import getApiExceptionObjectFromAxiosError from "../../utils/ProcessException";
import { useEffect, useState } from "react";
import Modal from "../../components/Common/Modal";
import { IzobrazevanjeStatus } from "../../utils/Enums";

const IzobrazevanjeEdit = () => {
    const { izobrazevanjeId } = useParams();
    if (!izobrazevanjeId)
        throw Error("Manjka parameter izobrazevanjeId");

    const izobrazevanjeIdInt = parseInt(izobrazevanjeId);

    const { data, status, isSuccess } = useFetchIzobrazevanjeWithSifranti(izobrazevanjeIdInt);
    const updateIzobrazevanjeMutatiedDraft = useUpdateIzobrazevanjeDraft();
    const updateIzobrazevanjeMutatiedPublish = useUpdateIzobrazevanjePublish();
    const deleteIzobrazevanje = useDeleteIzobrazevanje();
    const nav = useNavigate();

    let error = null;
    if (updateIzobrazevanjeMutatiedDraft.error)
        error = updateIzobrazevanjeMutatiedDraft.error;
    if (updateIzobrazevanjeMutatiedPublish.error)
        error = updateIzobrazevanjeMutatiedPublish.error;

    if (!isSuccess)
        return (
            <Section>
                <Heading>Urejanje izobraževanja
                    <Divider />
                </Heading>
                <ApiStatus status={status}></ApiStatus>
            </Section>
        )   

    if (data.izobrazevanje.status == IzobrazevanjeStatus.Objavljeno)
        nav(`/izobrazevanje/${data.izobrazevanje.izobrazevanjeId}`);
    
    return (
        <Section>
            <Heading>
                Urejanje izobraževanja
                <Divider />
                <p>
                    <span className="red">*</span>
                    polja, označena z zvezdico, so obvezna
                </p>
            </Heading>
            <IzobrazevanjeForm
                izobrazevanje={data.izobrazevanje}
                izobrazevanjeTipi={data.izobrazevanjeTipi}
                podrocja={data.podrocja}
                submittedDraft={(i) => updateIzobrazevanjeMutatiedDraft.mutate(i)}
                submittedPublish={(i) => updateIzobrazevanjeMutatiedPublish.mutate(i)}
                deleted={(i) => deleteIzobrazevanje.mutate(i)}
                apiException={getApiExceptionObjectFromAxiosError(error)}
            />
        </Section>
    );
}

export default IzobrazevanjeEdit;