import { useParams } from "react-router-dom";
import ApiStatus from "../../../components/ApiStatus";
import Divider from "../../../components/Common/Divider";
import Heading from "../../../components/Common/Heading";
import Section from "../../../components/Common/Section";
import getApiExceptionObjectFromAxiosError from "../../../utils/ProcessException";
import PodrocjeForm from "./PodrocjeForm";
import { useFetchPodrocje, useUpdatePodrocje, useArchivePodrocje, useReactivatePodrocje, useDeletePodrocje } from "./PodrocjeHooks"

const PodrocjeEdit = () => {
    const { organizacijaId } = useParams();

    if (!organizacijaId)
        throw Error("Manjka parameter organizacijaId");

    const podrocjeIdInt = parseInt(organizacijaId);

    const { data, status, isSuccess } = useFetchPodrocje(podrocjeIdInt);
    const updatePodrocjeMutated = useUpdatePodrocje();
    const archivePodrocjeMutated = useArchivePodrocje();
    const reactivatePodrocjeMutated = useReactivatePodrocje();   
    const deletePodrocjeMutated = useDeletePodrocje();   

    if (!isSuccess)
        return (
            <Section>
                <Heading>Urejanje organizacije
                    <Divider />
                </Heading>
                <ApiStatus status={status}></ApiStatus>
            </Section>
        )

    return (
        <Section>
            <PodrocjeForm
                podrocje={(data!)}
                submitted={(p) => updatePodrocjeMutated.mutate(p)}
                submittedArchive={() => archivePodrocjeMutated.mutate(podrocjeIdInt)}
                submittedReactivate={() => reactivatePodrocjeMutated.mutate(podrocjeIdInt)}
                deleted={() => deletePodrocjeMutated.mutate(podrocjeIdInt)}
                apiException={getApiExceptionObjectFromAxiosError(updatePodrocjeMutated?.error)} />
        </Section>
    );
}

export default PodrocjeEdit;