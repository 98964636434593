import styled from 'styled-components'

const StyledFormContainer = styled.div`
    max-width: 1050px;
`

interface Props {
    children: React.ReactNode
}

const FormContainer = ({ children }: Props) => {
    return (
        <StyledFormContainer>
            {children}
        </StyledFormContainer>
    )
}

export default FormContainer