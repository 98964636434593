import Divider from "../../components/Common/Divider";
import Heading from "../../components/Common/Heading";
import Section from "../../components/Common/Section";

const Unauthorized = () => {
    return (
        <Section>
            <Heading>Napaka
                <Divider />
            </Heading>
            <p>
                Unauthorized
            </p>
        </Section>
    )
}

export default Unauthorized;