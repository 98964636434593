import styled from "styled-components"
import Icon from "./Icon"
import Nullable from "../../types/Nullable"

interface StyledCardArgs {
    color: string
}

const StyledCard = styled.div<StyledCardArgs>`
    transition: all 0.15s;
    display: flex;
    background-color: ${props => props.theme.colors.background};
    border: ${props => props.theme.colors.border} 1px solid;
    width: 430px;
    align-items: stretch;
    margin: 0 1rem 1rem 0;
    height: fit-content;

    &:hover{
        cursor: pointer;
        background-color: #c6e7f4;
    }

    &:active{
        transform: scale(0.98);
    }

    .left{
        background-color: ${props => props.color};
        display: flex;
        align-items: center;
    }
    .icon{
        font-size: min(10vw, 3rem);
        padding: 1rem;
        margin: 0;
    }

    h3{
        font-size: min(5vw, 20px);
        font-weight: 400;
        margin: 0;
        margin-bottom: 0.3rem;
    }
    p{
        margin: 0;
        margin-bottom: 0.2rem;
        font-size: min(4vw, 1rem);
        color: #818181;
    }

    .middle{
        flex: 1;
        margin-left: 1rem;
        display: flex;
        padding: 0.4rem 0;
        flex-direction: column;
        justify-content: space-between;
        word-break: break-word;
    }

    .right{
        align-self: center;
        padding: 1rem;
        margin-left: auto;
        color: ${props => props.theme.colors.background};
        font-weight: 600;
        
        .icon{
            font-size: min(8vw, 2.5rem);
            position: relative;
            color: #818181;
            padding: 0;
        }
        p{
            color: ${props => props.theme.colors.background};
            font-size: min(4vw, 1rem);
            font-weight: 600;
            position: absolute;
            top: 49%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    @media (max-width: 650px) {        
        width: 100%;
    }
`
interface CardArgs {
    icon: string
    title: string
    fromDate: string
    toDate: string
    stTock: Nullable<number>
    color: string
    showProstaMestaLabel: boolean
    prijavaOmejena?: boolean
    steviloProstihMest?: Nullable<number>
    onClick: () => void
}

const Card = (cardArgs: CardArgs) => {
    console.log("cardArgs", cardArgs);
    console.log("!cardArgs.steviloProstihMest PRIJAVA", cardArgs.steviloProstihMest && cardArgs.prijavaOmejena);
    return (
        
        <StyledCard onClick={cardArgs.onClick} color={cardArgs.color}>
            <div className="left">
                <Icon name={cardArgs.icon} />
            </div>
            <div className="middle">
                <h3>{cardArgs.title}</h3>
                <p>{cardArgs.fromDate} - {cardArgs.toDate}</p>
                { cardArgs.showProstaMestaLabel &&
                    (<>
                    {!cardArgs.prijavaOmejena &&
                        <p><b>ni omejitve prijave</b></p>
                    }
                    { cardArgs.steviloProstihMest != null &&
                        cardArgs.prijavaOmejena &&
                        <p>Št. prostih mest: <b>{cardArgs.steviloProstihMest}</b></p>
                    }
                    </>)
                }
            </div>
            {/* {cardArgs.stTock !== null &&
                <div className="right">
                    <Icon name="crop square" full>
                        <p>{cardArgs.stTock}</p>
                    </Icon>
                </div>
            } */}
        </StyledCard>
    )
}

export default Card