import { useEffect, useState } from "react"

const useSort = <T>(data: Array<T> | undefined) => {
    const [sortedData, setSortedData] = useState<Array<T>>();
    const [sortColumn, setSortColumn] = useState<null | string>(null);
    const [sortIcon, setSortIcon] = useState('arrow_drop_down');

    useEffect(() => {
        if (sortColumn) {
            sortData(data, sortColumn!);
            setSortedData(data);
        } else {
            setSortedData(data);
        }
    }, [data])

    const reverseData = <T>(tmpData: Array<T> | undefined) => {
        var reversedData: Array<T> | undefined = [];
        if (tmpData) {
            const reversedKeys = Object.keys(tmpData).reverse();
            reversedKeys.forEach((key) => {
                reversedData?.push(tmpData![key as any])
            });
        }
        return reversedData
    }

    const setSort = (column: string) => {
        if (sortedData && sortColumn === column) {
            sortData(sortedData, column, true);
        } else {
            setSortColumn(column);
            sortData(sortedData, column);
        }
    }

    const sortData = (tmpData: Array<T> | undefined, key: string, reverse: boolean = false) => {
        if (reverse) {
            setSortIcon(sortIcon === 'arrow_drop_down' ? 'arrow_drop_up' : 'arrow_drop_down');
            setSortedData(reverseData(tmpData));
        } else {
            var sortedTmpData = tmpData?.sort((a: any, b: any) => {
                if (typeof a[key] == 'string') {
                    return a[key].toLowerCase().localeCompare(b[key].toLowerCase());
                } else {
                    if (a[key] < b[key]) {
                        return -1;
                    }
                    if (a[key] > b[key]) {
                        return 1;
                    }
                    return 0;
                }
            })
            setSortIcon('arrow_drop_up');
            setSortedData(sortedTmpData);
        }
    }

    return { setSort, sortedData, sortColumn, sortIcon }
}

export default useSort