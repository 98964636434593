import Button from "../../components/Common/Button";
import CardOverview from "../../components/Common/CardOverview";
import { useFetchIzobrazevanjeDetail } from "../izobrazevanje/IzobrazevanjeHooks";

interface PrijavaDetailArgs {
    back: () => void,
    izobrazevanjeId: number
}


const PrijavaDetail = ({ back, izobrazevanjeId }: PrijavaDetailArgs) => {
    const { data, status, isSuccess } = useFetchIzobrazevanjeDetail(izobrazevanjeId);

    return (
        <>
            <Button startIcon="arrow_back_ios" onClick={back}>
                Nazaj na seznam
            </Button>
            {isSuccess && data &&
                <>
                    <CardOverview izobrazevanje={data}></CardOverview>
                    <Button startIcon="arrow_back_ios" onClick={back}>
                        Nazaj na seznam
                    </Button>
                </>
            }
        </>
    );
}

export default PrijavaDetail;