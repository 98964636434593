import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from "react-router-dom";
import AppConfig from "../../../AppConfig";
import { PodrocjeFormType } from "../../../types/Podrocje/PodrocjeFormType";
import { PodrocjeListType } from "../../../types/Podrocje/PodrocjeListType";
import acquireToken from "../../login/Authorization";
import { useMsal } from "@azure/msal-react";
import { useContext } from "react";
import { FeedbackContext, Status } from "../../../context/FeedbackContext";
import getApiExceptionObjectFromAxiosError from "../../../utils/ProcessException";

// TODO: ena funkcija, ki izvaja klice, da se ne podvaja logika za vse klice

// GET ALL
const useFetchPodrocjaAll = () => {
    const { instance, accounts } = useMsal();
    return useQuery<PodrocjeListType[], AxiosError>(["podrocja"], () =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.get(`${AppConfig.baseApiUrl}/podrocje/all`,
                { headers: { Authorization: `Bearer ${accessToken}` } })
                .then((resp) => resp.data)
        ));
};

// GET BY ID
const useFetchPodrocje = (podrocjeId: number) => {
    const { instance, accounts } = useMsal();
    return useQuery<PodrocjeFormType, AxiosError>(["podrocje", podrocjeId], () =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.get(`${AppConfig.baseApiUrl}/podrocje/${podrocjeId}`,
                { headers: { Authorization: `Bearer ${accessToken}` } })
                .then((resp) => resp.data)
        ));
}

// POST
const useAddPodrocje = () => {
    const { displayFeedback } = useContext(FeedbackContext);
    const { instance, accounts } = useMsal();
    const nav = useNavigate();
    const queryClient = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, PodrocjeFormType>((p) =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.post(`${AppConfig.baseApiUrl}/podrocje`, p,
                { headers: { Authorization: `Bearer ${accessToken}` } })),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["podrocja"]);
                nav("/sifrant/organizacija");
                if (displayFeedback)
                    displayFeedback("Organizacija je bila uspešno dodana.", Status.SUCCESS)
            },
            onError: (err) => {
                let errMsg = "Pri ustvarjanju organizacije je prišlo do napake."
                let apiException = getApiExceptionObjectFromAxiosError(err);
                if(apiException?.Message)
                    errMsg = apiException.Message;

                // TODO?: mogoče se lahko tu kaj poenoti za exception handling...
                if (displayFeedback)
                    displayFeedback(errMsg, Status.ERROR)
            }
        }
    );
};

// PUT - UPDATE
const useUpdatePodrocje = () => {
    const { displayFeedback } = useContext(FeedbackContext);
    const { instance, accounts } = useMsal();
    const nav = useNavigate();
    const queryClient = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, PodrocjeFormType>((p) =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.put(`${AppConfig.baseApiUrl}/podrocje/${p.podrocjeId}`, p,
                { headers: { Authorization: `Bearer ${accessToken}` } })),
        {
            onSuccess: (_, p) => {
                queryClient.invalidateQueries(["podrocja"]);
                //nav(`/sifrant/organizacija/${p.podrocjeId}`);
                nav(`/sifrant/organizacija`);
                if (displayFeedback)
                    displayFeedback("Organizacija je bila uspešno urejena.", Status.SUCCESS)
            },
            onError: (err) => {
                let errMsg = "Pri posodabljanju organizacije je prišlo do napake."
                let apiException = getApiExceptionObjectFromAxiosError(err);
                if(apiException?.Message)
                    errMsg = apiException.Message;

                // TODO?: mogoče se lahko tu kaj poenoti za exception handling...
                if (displayFeedback)
                    displayFeedback(errMsg, Status.ERROR)
            }
        }
    );
};

// ARCHIVE
const useArchivePodrocje = () => {
    const { displayFeedback } = useContext(FeedbackContext);
    const { instance, accounts } = useMsal();
    const nav = useNavigate();
    const queryClient = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, number>((podrocjeId) =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.put(`${AppConfig.baseApiUrl}/podrocje/archive/${podrocjeId}`, null,
                { headers: { Authorization: `Bearer ${accessToken}` } })),
        {
            onSuccess: (_, podrocjeId) => {
                queryClient.invalidateQueries(["podrocja"]);
                nav(`/sifrant/organizacija`);
                if (displayFeedback)
                    displayFeedback("Organizacija je bila uspešno arhivirana.", Status.SUCCESS)
            },
            onError: (err) => {
                let errMsg = "Pri arhiviranju organizacije je prišlo do napake."
                let apiException = getApiExceptionObjectFromAxiosError(err);
                if(apiException?.Message)
                    errMsg = apiException.Message;

                // TODO?: mogoče se lahko tu kaj poenoti za exception handling...
                if (displayFeedback)
                    displayFeedback(errMsg, Status.ERROR)
            }
        }
    );
};

// REACTIVATE
const useReactivatePodrocje = () => {
    const { displayFeedback } = useContext(FeedbackContext);
    const { instance, accounts } = useMsal();
    const nav = useNavigate();
    const queryClient = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, number>((podrocjeId) =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.put(`${AppConfig.baseApiUrl}/podrocje/reactivate/${podrocjeId}`, null,
                { headers: { Authorization: `Bearer ${accessToken}` } })),
        {
            onSuccess: (_, podrocjeId) => {
                queryClient.invalidateQueries(["podrocja"]);
                nav(`/sifrant/organizacija`);
                if (displayFeedback)
                    displayFeedback("Organizacija je bila uspešno aktivirana.", Status.SUCCESS)
            },
            onError: (err) => {
                let errMsg = "Pri aktiviranju organizacije je prišlo do napake."
                let apiException = getApiExceptionObjectFromAxiosError(err);
                if(apiException?.Message)
                    errMsg = apiException.Message;

                // TODO?: mogoče se lahko tu kaj poenoti za exception handling...
                if (displayFeedback)
                    displayFeedback(errMsg, Status.ERROR)
            }
        }
    );
};

// DELETE
const useDeletePodrocje = () => {
    const { displayFeedback } = useContext(FeedbackContext);
    const { instance, accounts } = useMsal();
    const nav = useNavigate();
    const queryClient = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, number>((podrocjeId) =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.delete(`${AppConfig.baseApiUrl}/podrocje/${podrocjeId}`,
                { headers: { Authorization: `Bearer ${accessToken}` } })),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["podrocja"]);
                nav("/sifrant/organizacija");
                if (displayFeedback)
                    displayFeedback("Organizacija je bila uspešno odstranjena.", Status.SUCCESS)
            },
            onError: (err) => {
                let errMsg = "Pri brisanju organizacije je prišlo do napake."
                let apiException = getApiExceptionObjectFromAxiosError(err);
                if(apiException?.Message)
                    errMsg = apiException.Message;

                // TODO?: mogoče se lahko tu kaj poenoti za exception handling...
                if (displayFeedback)
                    displayFeedback(errMsg, Status.ERROR)
            }
        }
    );
};

export default useFetchPodrocjaAll;
export { useFetchPodrocje, useUpdatePodrocje, useAddPodrocje, useArchivePodrocje, useReactivatePodrocje, useDeletePodrocje };