import { useParams } from "react-router-dom";
import ApiStatus from "../../../components/ApiStatus";
import Divider from "../../../components/Common/Divider";
import Heading from "../../../components/Common/Heading";
import Section from "../../../components/Common/Section";
import getApiExceptionObjectFromAxiosError from "../../../utils/ProcessException";
import IzobrazevanjeTipForm from "./IzobrazevanjeTipForm";
import { useArchiveIzobrazevanjeTip, useFetchIzobrazevanjeTip, useUpdateIzobrazevanjeTip, useReactivateIzobrazevanjeTip, useDeleteIzobrazevanjeTip } from "./IzobrazevanjeTipHooks"

const IzobrazevanjeTipEdit = () => {
    const { izobrazevanjeTipId } = useParams();
    if (!izobrazevanjeTipId)
        throw Error("Manjkajoč izobrazevanjeTipId");

    const izobrazevanjeTipIdInt = parseInt(izobrazevanjeTipId);

    const { data, status, isSuccess } = useFetchIzobrazevanjeTip(izobrazevanjeTipIdInt);
    const updateIzobrazevanjeTipMutated = useUpdateIzobrazevanjeTip();
    const archiveIzobrazevanjeTipMutated = useArchiveIzobrazevanjeTip();
    const reactivateIzobrazevanjeTipMutated = useReactivateIzobrazevanjeTip();
    const deleteIzobrazevanjeTipMutated = useDeleteIzobrazevanjeTip();
    
    if (!isSuccess)
        return (
            <Section>
                <Heading>Urejanje tipa izobraževanja
                    <Divider />
                </Heading>
                <ApiStatus status={status}></ApiStatus>
            </Section>
        )

    return (
        <Section>
            <IzobrazevanjeTipForm
                izobrazevanjeTip={(data!)}
                submitted={(izobrazevanjaTip) => updateIzobrazevanjeTipMutated.mutate(izobrazevanjaTip)}
                submittedArchive={() => archiveIzobrazevanjeTipMutated.mutate(izobrazevanjeTipIdInt)}
                submittedReactivate={() => reactivateIzobrazevanjeTipMutated.mutate(izobrazevanjeTipIdInt)}
                deleted={() => deleteIzobrazevanjeTipMutated.mutate(izobrazevanjeTipIdInt)}
                apiException={getApiExceptionObjectFromAxiosError(updateIzobrazevanjeTipMutated?.error)}
            />
        </Section>
    );
}

export default IzobrazevanjeTipEdit;