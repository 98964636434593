import { useState } from "react";
import Section from "../../components/Common/Section"
import TabMenu from "../../components/Navigation/TabMenu"
import TabItem from "../../components/Navigation/TabItem"
import AktualnoTab from "./AktualnoTab";
import MojePrijaveTab from "./MojePrijaveTab";
import PrijavaDetail from "./PrijavaDetail";
import ZakljucenoTab from "./ZakljucenoTab";

enum Pages {
    Aktualno,
    Prijave,
    Zakljuceno,
}

const PrijavaList = () => {
    const [izobrazevanjeId, setIzobrazevanjeId] = useState<number>(-1);
    const [cardIsOpen, setCardIsOpen] = useState<boolean>(false)
    const [activeTab, setActiveTab] = useState<Pages>(Pages.Aktualno)

    const switchTab = (index: number) => {
        setCardIsOpen(false);
        setActiveTab(index);
    }

    function showIzobrazevanjeDetail(izobrazevanjeId: number) {
        setIzobrazevanjeId(izobrazevanjeId);
        setCardIsOpen(true);
    }


    return (
        <Section>
            <TabMenu>
                <TabItem index={0} handleClick={switchTab} active={activeTab === Pages.Aktualno} title="aktualno" />
                <TabItem index={1} handleClick={switchTab} active={activeTab === Pages.Prijave} title="moje prijave" />
                <TabItem index={2} handleClick={switchTab} active={activeTab === Pages.Zakljuceno} title="zaključeno" />
            </TabMenu>
            {!cardIsOpen ?
                <>
                    {activeTab === Pages.Aktualno &&
                        <AktualnoTab key="aktualno" handleItemClick={showIzobrazevanjeDetail} />
                    }
                    {activeTab === Pages.Prijave &&
                        <MojePrijaveTab key="mojePrijave" handleItemClick={showIzobrazevanjeDetail} />
                    }
                    {activeTab === Pages.Zakljuceno &&
                        <ZakljucenoTab key="zakljuceno" handleItemClick={showIzobrazevanjeDetail} />
                    }
                </>
                :
                <>
                    <PrijavaDetail
                        izobrazevanjeId={izobrazevanjeId}
                        back={() => { setCardIsOpen(false) }}
                    />
                </>
            }


        </Section>
    );
}

export default PrijavaList;
