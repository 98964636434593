import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-common";
import { AccessTokenRequest } from "../../AppConfig";
import useAddUporabnik from "./LoginHooks";
import PrijavaList from "../prijava/PrijavaList";
import { Navigate } from "react-router-dom";

const Login = () => {
    const { instance } = useMsal();
    const addUporabnikMutatied = useAddUporabnik(null);

    useEffect(() => {
        instance.handleRedirectPromise().then((response) => {
            const request = {
                ...AccessTokenRequest,
                account: response?.account as AccountInfo
            };

            // Silently acquires an access token which is then attached to a request for API data
            instance.acquireTokenSilent(request)
                .then((response) => addUporabnikMutatied.mutate(response.accessToken))
                .catch(() => instance.acquireTokenRedirect(request));
        });
    }, []);

    return (
        <Navigate to="/" />
    );
}

export default Login;