import styled from 'styled-components'

interface StyledFormItemsArgs {
    labelsWidth?: string
}

const StyledFormItems = styled.form<StyledFormItemsArgs>`
    width: 100%;
    background-color: ${props => props.theme.colors.background};
    padding: 1rem 2rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
    border: ${props => props.theme.colors.border} 1px solid;
    border-radius: 4px;

    display: grid;
    row-gap: 20px;
    grid-template-columns: ${props => props.labelsWidth || 'fit-content'} 1fr;
    overflow-x: auto;

    .error{
        font-size: 14px;
        font-weight: 700;
        grid-column: 2 / 3;
        margin-top: -13px;
    }

    label {
        text-align: right;
        margin-right: 1rem;
        margin-top: 0.2rem;
    }
    input, select{
        accent-color: ${props => props.theme.colors.primary};
        border-radius: 4px;
        border: 1px solid ${props => props.theme.colors.border};
        padding: 0.4rem 0.8rem;
        margin: 0;
        margin-right: 1rem;
    }
    input, select, textarea{
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    }
    input:focus, select:focus, textarea:focus{
        outline: 1px solid ${props => props.theme.colors.border};
    }

    @media (max-width: 650px){
        *{
            grid-column: 1 / -1;
        }
        .error {
            grid-column: 1 / 3;
        }
        input[type=datetime-local]{
            display: block;
            margin-bottom: 0.5rem;
        }
    }

    @media (max-width: 910px) {
        gap: 0;
        label {
            width: 100%;
            text-align: left;
            margin-right: 0;
            margin-bottom: 0.5rem;
        }
        input, textarea, select, input[type=datetime-local] {
            margin-bottom: 1rem;
        }
    }
`

interface FormItemsArgs {
    children: React.ReactNode,
    labelsWidth?: string
}

const FormItems = ({ children, labelsWidth }: FormItemsArgs) => {
    return (
        <StyledFormItems labelsWidth={labelsWidth}>{children}</StyledFormItems>
    )
}

export default FormItems