import styled from 'styled-components'
import Icon from '../Common/Icon'
import prijavaLogo from '../../assets/prijava_logo.png'
import ministrstvoLogo from '../../assets/mddsz_logo.png'
import helpStran from '../../assets/help/Postopek prijave na izobraževanje.pdf'
import { Link } from 'react-router-dom'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { LoginRequest } from "../../AppConfig";
import { AuthContext } from "../../context/AuthContext";
import Button from '../Common/Button'


const StyledTopbar = styled.section`
    color: white;
    grid-area: topbar;
    width: 100%;
    height: 100%;
    background-color: #457081;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    padding: 0 3rem 0 1rem;
    transition: all 0.15s;
    div {
        width: fit-content;
        height: fit-content;
        display: flex;
        align-items: center;
        img{
            height: min(9vw, 2.5rem);
            margin-right: 2rem;
        }
    }
    .hamburger{
        transition: all 0.15s;
    }
    .topbar-elements{
        transition: all 0.15s;
        margin-right: 2rem;
    }
    .topbar-elements:hover{
        transform: scale(1.05);
    }
    .avatar{
        border-radius: 50%;
        margin-right: 1rem;
    }
    .hamburger:active{
        transform: scale(0.9);
    }

    @media (min-width: 1000px) {
        .hamburger{
            display: none;
        }
    }

    @media (max-width: 1000px) {
        .profile, .log{
            display: none;
        }
        padding: 0 1rem;
    }
    @media (max-width: 820px) {
        .logo{
            display: none;
        }
    }
    @media (max-width: 500px) {
        .help{
            display: none;
        }
    }
`

interface TopbarArgs {
    hamburgerClick: () => void
}

const Topbar = ({ hamburgerClick }: TopbarArgs) => {
    const { instance } = useMsal();

    const handleLogin: React.MouseEventHandler<HTMLButtonElement> = () => {
        instance.loginRedirect(LoginRequest);
    }

    const handleLogout: React.MouseEventHandler<HTMLButtonElement> = () => {
        instance.logoutRedirect({ postLogoutRedirectUri: "/" });
    }

    return (
        <StyledTopbar id='topbar'>
            <div className='left'>
                <img src={prijavaLogo} alt="Logotip spletne strani" />
                <img className='logo' src={ministrstvoLogo} alt="Logotip ministrstva za delo, družino, socialne zadeve in enake možnosti" />
            </div>
            <div className='right'>
                <AuthenticatedTemplate>
                    <AuthContext.Consumer>
                        {({ loggedInUser }) => (
                            <>
                                <Link to={helpStran} target='_blank'>
                                    <div className='topbar-elements help'>
                                        <Icon name='help' />
                                        <p>pomoč</p>
                                    </div>
                                </Link>
                                <Link to='/'>
                                    <div className='topbar-elements profile'>
                                        {loggedInUser?.photo ?
                                            <img className='avatar' alt='Profile' src={`data:image/jpg;base64,${loggedInUser.photo}`} />
                                            :
                                            <Icon name='person' />
                                        }
                                        <p>{loggedInUser?.ime} {loggedInUser?.priimek}</p>
                                    </div>
                                </Link>
                            </>
                        )}
                    </AuthContext.Consumer>

                    <Button className='log' tertiary onClick={handleLogout}>Odjava</Button>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Button className='log' tertiary onClick={handleLogin}>Prijava</Button>
                </UnauthenticatedTemplate>
                <div onClick={hamburgerClick} className='hamburger'>
                    <Icon name='menu' />
                </div>
            </div>
        </StyledTopbar>
    )
}

export default Topbar