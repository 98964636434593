import Section from "../../components/Common/Section";
import Heading from "../../components/Common/Heading";
import Divider from "../../components/Common/Divider";
import Table from "../../components/Common/Table";
import { format } from 'date-fns';
import { Link } from "react-router-dom";
import Button from "../../components/Common/Button";
import FilterItems from "../../components/Filter/FilterItems";
import FilterItem from "../../components/Filter/FilterItem";
import ApiStatus from "../../components/ApiStatus";
import useFetchIzobrazevanjeAll from "./IzobrazevanjeHooks";
import usePagination from "../../components/Common/PaginationHooks";
import useSort from "../../components/Common/SortingHooks";
import TableHeader from "../../components/Common/TableHeader";
import timezoneShift from "../../utils/TimezoneShift";
import { IzobrazevanjeListType } from "../../types/Izobrazevanje/IzobrazevanjeListType";
import { IzobrazevanjeStatus } from "../../utils/Enums";
import IzobrazevanjeListTable from "./IzobrazevanjeListTable";
import IzobrazevanjeListTableFiltri from "./IzobrazevanjeListTableFiltri";
import { useEffect } from "react";

const IzobrazevanjeList = () => {

    const { data, status, isSuccess } = useFetchIzobrazevanjeAll();

    if (!isSuccess)
        return (
            <Section>
                <Heading>Pregled izobraževanj
                    <Divider />
                </Heading>
                <ApiStatus status={status}></ApiStatus>
            </Section>
        )

    return (
        <Section>
            <Heading>
                Pregled izobraževanj
                <Divider />
            </Heading>
            <IzobrazevanjeListTableFiltri 
                izobrazevanjeListData={data}
            />
        </Section>
    );
}

export default IzobrazevanjeList;
