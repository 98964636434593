import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    body{
        margin: 0;
        padding: 0;
        height: 100vh;
    }
    html {
        scroll-behavior: smooth;
    }
    *{
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        text-decoration: none;
    }
    a{
        color: white;
    }
    .material-symbols-rounded {
        font-variation-settings:
            'FILL' 0,
            'wght' 300,
            'GRAD' 0,
            'opsz' 48;
        font-size: 1.9rem;
    }
    .red {
        color: red;
    }
    .bold {
        font-weight: bold;
    }
`;

export default GlobalStyle;