import styled from "styled-components"

interface StyledTabItemArgs {
    active: boolean,
}

const StyledTabItem = styled.div<StyledTabItemArgs>`
    display: inline-block;
    transition: all 0.2s;
    border-bottom: 3px solid #497e9300 ;
    
    font-size: min(6vw, 36px);
    /* font-size: 36px; */

    font-weight: 300;
    padding-bottom: 0.5rem;
    margin-bottom: -3px;
    margin-right: 4rem;
    width: fit-content;
    ${props => props.active && `
        border-bottom: 3px solid #497e93ee ;
        color: #497e93 ;
    `}
    &:hover{
        cursor: pointer;
        transform: translateX(3px);
    }
`

interface TabItemArgs {
    title: string,
    active: boolean,
    index: number,
    handleClick: (index: number) => void
}

const TabItem = ({ title, active, index, handleClick }: TabItemArgs) => {
    return (
        <StyledTabItem onClick={function () { handleClick(index) }} active={active}>
            <span>
                {title}
            </span>
        </StyledTabItem>
    )
}

export default TabItem