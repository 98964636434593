import ReactPaginate from "react-paginate"
import styled from "styled-components"

const StyledPaginate = styled.div`
    min-width: 100%;
    width: fit-content;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${props => props.theme.colors.secondary};
    a{
        color: white !important;
        text-decoration: none !important;
    }
    a:hover{
        font-weight: 400;
    }
    .sidenote{
        padding: 0.5rem 1rem;
    }
    .clickable:hover{
        cursor: pointer;
        background-color: ${props => props.theme.colors.primary};
    }
    .active{
        background-color: ${props => props.theme.colors.tertiary};
        font-weight: 700;
    }
    .active:hover{
        background-color: ${props => props.theme.colors.tertiary};
    }
    ul {
        display: flex;
        justify-content: center;
        list-style: none;
        margin: 0;
        li {
            color: black;
            a{
                display: block;
                padding: 0.5rem 1rem;
            }
        }
        li:hover{
            background-color: ${props => props.theme.colors.primary};
            cursor: pointer;
        }
    }
`

interface PaginateArgs {
    pageCount: number,
    onPageChange: (selectedItem: { selected: number }) => void,
    paginate?: boolean,
    setPaginate?: React.Dispatch<React.SetStateAction<boolean>>
}

const Paginate = ({ pageCount, onPageChange, paginate, setPaginate }: PaginateArgs) => {

    return (
        <StyledPaginate>
            <div className="sidenote">
                Strani: {paginate ? pageCount : '1'}
            </div>
            {
                paginate &&
                <ReactPaginate
                    activeClassName="active"
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={onPageChange}
                />
            }
            <div className="sidenote clickable" onClick={
                setPaginate && (() => setPaginate(!paginate))
            }>
                {paginate ? 'Vse na eni strani' : 'Po straneh'}
            </div>

        </StyledPaginate>
    )
}

export default Paginate