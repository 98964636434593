import { createContext, useEffect, useState } from "react";

export enum Status {
    SUCCESS = "success",
    ERROR = "error",
    WARNING = "warning"
}
export interface FeedbackContextProviderProps {
    feedback?: string;
    visible?: boolean;
    status?: Status;
    displayFeedback?: (feedback: string, status: Status) => void;
}

export const FeedbackContext = createContext<FeedbackContextProviderProps>({});

type FeedbackProviderArgs = {
    children?: React.ReactNode;
}

const FeedbackProvider = ({ children }: FeedbackProviderArgs) => {

    const [feedback, setFeedback] = useState<string>("");
    const [visible, setVisible] = useState<boolean>(false);
    const [status, setStatus] = useState<Status>(Status.SUCCESS);

    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

    const timeFeedback = () => {
        clearTimeout(timeoutId)
        setVisible(true);
        setTimeoutId(
            setTimeout(() => {
                setVisible(false);
            }, 4000)
        )
    }

    const displayFeedback = (feedback: string, status: Status) => {
        setFeedback(feedback);
        setStatus(status);
        timeFeedback();
    }

    return (
        <FeedbackContext.Provider value={{
            feedback,
            visible,
            status,
            displayFeedback
        }}>
            {children}
        </FeedbackContext.Provider>
    );
}

export { FeedbackProvider };