export enum IzobrazevanjeStatus {
    VPripravi = 0,
    Objavljeno = 1
}

export enum IzobrazevanjeTipStatus {
    Neznano = 0,
    Aktivno = 1,
    Arhivirano = 9
}

export enum UserRole {
    Administrator,
    User
}

export enum PodrocjeStatus {
    Neznano = 0,
    Aktivno = 1,
    Arhivirano = 9
}

export const PostsPerPage: number = 15;