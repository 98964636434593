import { useState } from "react";
import Button from "../../../components/Common/Button";
import FormActions from "../../../components/Form/FormActions";
import FormContainer from "../../../components/Form/FormContainer";
import FormItems from "../../../components/Form/FormItems";
import Heading from "../../../components/Common/Heading";
import Divider from "../../../components/Common/Divider";
import ApiException from "../../../types/ApiException";
import { PodrocjeFormType } from "../../../types/Podrocje/PodrocjeFormType";
import TextInput from '../../../components/Form/TextInput';
import { useNavigate } from "react-router";
import { PodrocjeStatus } from "../../../utils/Enums";
import Modal from "../../../components/Common/Modal";

type PodrocjeFormArgs = {
    podrocje: PodrocjeFormType;
    submitted: (podrocje: PodrocjeFormType) => void;
    submittedArchive?: (podrocje: PodrocjeFormType) => void;
    submittedReactivate?: (podrocje: PodrocjeFormType) => void;
    deleted?: (podrocje: PodrocjeFormType) => void;
    apiException: ApiException | null;
}

const PodrocjeForm = ({ podrocje, submitted, submittedArchive, submittedReactivate, deleted, apiException }: PodrocjeFormArgs) => {
    const nav = useNavigate();
    const [podrocjeState, setPodrocjeState] = useState({ ...podrocje });
    const [archiveModal, setShowArchiveModal] = useState(false);
    const [deleteModal, setShowDeleteModal] = useState(false);

    const onSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        e.preventDefault();
        submitted(podrocjeState);
    }

    const onSubmitArchive: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        if (submittedArchive) {
            e.preventDefault();
            submittedArchive(podrocjeState);
            setShowArchiveModal(false);
        }
    }

    const onSubmitReactivate: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        if (submittedReactivate) {
            e.preventDefault();
            submittedReactivate(podrocjeState);
        }
    }

    const showArchiveModal: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        e.preventDefault();
        setShowArchiveModal(true);
    }
    
    const showDeleteModal: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        e.preventDefault();
        setShowDeleteModal(true);
    }

    const onSubmitDelete: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        if (deleted) {
            e.preventDefault();
            deleted(podrocjeState);
            setShowDeleteModal(false);
        }
    }

    return (
        <>
            <Heading>Urejanje organizacije
                <Divider />
                <p>
                    <span className="red">*</span>
                    polja označena z zvezdico so obvezna
                </p>
            </Heading>

                <FormContainer>
                    {/* arhiviraj se samo prikaže če je sifrant že ustvarjen in je aktiven */}
                    {podrocje.status == PodrocjeStatus.Aktivno && submittedArchive &&
                        <div>
                            <Modal 
                                visible={archiveModal}
                                message="Ali ste prepričani da želite arhivirati to organizacijo?"
                                actionMessage="arhiviraj"
                                actionIcon="archive"
                                onCancel={() => setShowArchiveModal(false)}
                                onConfirm={onSubmitArchive}
                            />
                            <FormActions>
                                <div></div>
                                <Button className="right" startIcon="archive" tertiary onClick={showArchiveModal}>Arhiviraj</Button>
                            </FormActions>
                        </div>
                        
                    }

                    {podrocje.status == PodrocjeStatus.Arhivirano &&
                        <div>
                            <FormActions>
                                <div></div>
                                <Button className="right" startIcon="done" tertiary onClick={onSubmitReactivate}>Aktiviraj</Button>
                            </FormActions>
                            <p className="red">Organizacija je arhivirana. Za urejanje jo je potrebno aktivirati.</p>
                        </div>
                    }

                    <FormItems labelsWidth="25%">
                        <TextInput
                            disabled={podrocje.status == PodrocjeStatus.Arhivirano ? true : false}
                            width="350px"
                            value={podrocjeState.naziv}
                            type="text"
                            err={apiException}
                            errField="Naziv"
                            label='Naziv'
                            onChange={(e) => {
                                setPodrocjeState({ ...podrocjeState, naziv: e.target.value })
                            }}
                            required
                             />
                    </FormItems>
                    <FormActions>
                        {deleted &&
                            <div>
                                <Modal 
                                    visible={deleteModal}
                                    message="Ali ste prepričani da želite izbrisati to organizacijo?"
                                    actionMessage="izbriši"
                                    actionIcon="delete"
                                    onCancel={() => setShowDeleteModal(false)}
                                    onConfirm={onSubmitDelete}
                                />
                                <div></div>
                                <Button onClick={showDeleteModal} startIcon="delete" tertiary>izbriši</Button>
                            </div>
                        }
                        <div></div>
                        <div className="right">
                            {podrocje.status != PodrocjeStatus.Arhivirano 
                            && <Button startIcon="check" onClick={onSubmit}>shrani</Button>
                            }
                            <Button startIcon="close" cancel onClick={(e) => nav('/sifrant/organizacija')}>prekliči</Button>
                        </div>
                    </FormActions>
                </FormContainer>
        </>
    );
}

export default PodrocjeForm;