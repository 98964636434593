import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from "react-router-dom";
import AppConfig from "../../../AppConfig";
import { IzobrazevanjeTipFormType } from "../../../types/IzobrazevanjeTip/IzobrazevanjeTipFormType";
import { IzobrazevanjeTipListType } from "../../../types/IzobrazevanjeTip/IzobrazevanjeTipListType";
import { useMsal } from "@azure/msal-react";
import acquireToken from "../../login/Authorization";
import { useContext } from "react";
import { FeedbackContext, Status } from "../../../context/FeedbackContext";
import { IzobrazevanjeFormType } from "../../../types/Izobrazevanje/IzobrazevanjeFormType";
import getApiExceptionObjectFromAxiosError from "../../../utils/ProcessException";

// GET ALL
const useFetchIzobrazevanjeTipAll = () => {
    const { instance, accounts } = useMsal();
    return useQuery<IzobrazevanjeTipListType[], AxiosError>(["izobrazevanjaTipi"], () =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.get(`${AppConfig.baseApiUrl}/izobrazevanje_tip/all`,
                { headers: { Authorization: `Bearer ${accessToken}` } })
                .then((resp) => resp.data)
        ));
};

// GET BY ID
const useFetchIzobrazevanjeTip = (izobrazevanjeTipId: number) => {
    const { instance, accounts } = useMsal();
    return useQuery<IzobrazevanjeTipFormType, AxiosError>(["izobrazevanjaTipi", izobrazevanjeTipId], () =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.get(`${AppConfig.baseApiUrl}/izobrazevanje_tip/${izobrazevanjeTipId}`,
                { headers: { Authorization: `Bearer ${accessToken}` } })
                .then((resp) => resp.data)
        ));
};

// PUT-UPDATE
const useUpdateIzobrazevanjeTip = () => {
    const { displayFeedback } = useContext(FeedbackContext);
    const { instance, accounts } = useMsal();
    const nav = useNavigate();
    const queryClient = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, IzobrazevanjeTipFormType>((izobrazevanjaTip) =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.put(`${AppConfig.baseApiUrl}/izobrazevanje_tip/${izobrazevanjaTip.izobrazevanjeTipId}`, izobrazevanjaTip,
                { headers: { Authorization: `Bearer ${accessToken}` } })),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["izobrazevanjaTipi"]);
                nav("/sifrant/izobrazevanjeTip");
                if (displayFeedback)
                    displayFeedback("Tip izobraževanja je bil uspešno urejen.", Status.SUCCESS)
            },
            onError: (err) => {
                let errMsg = "Pri posodabljanju tipa izobraževanja je prišlo do napake."
                let apiException = getApiExceptionObjectFromAxiosError(err);
                if(apiException?.Message)
                    errMsg = apiException.Message;

                // TODO?: mogoče se lahko tu kaj poenoti za exception handling...
                if (displayFeedback)
                    displayFeedback(errMsg, Status.ERROR)
            }
        }
    );
};

// POST
const useAddIzobrazevanjeTip = () => {
    const { displayFeedback } = useContext(FeedbackContext);
    const { instance, accounts } = useMsal();
    const nav = useNavigate();
    const queryClient = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, IzobrazevanjeTipFormType>((izobrazevanjaTip) =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.post(`${AppConfig.baseApiUrl}/izobrazevanje_tip`, izobrazevanjaTip,
                { headers: { Authorization: `Bearer ${accessToken}` } })),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["izobrazevanjaTipi"]);
                nav("/sifrant/izobrazevanjeTip");
                if (displayFeedback)
                    displayFeedback("Tip izobraževanja je bil uspešno dodan.", Status.SUCCESS)
            },
            onError: (err) => {
                let errMsg = "Pri ustvarjanju tipa izobraževanja je prišlo do napake."
                let apiException = getApiExceptionObjectFromAxiosError(err);
                if(apiException?.Message)
                    errMsg = apiException.Message;

                // TODO?: mogoče se lahko tu kaj poenoti za exception handling...
                if (displayFeedback)
                    displayFeedback(errMsg, Status.ERROR)
            }
        }
    );
}

// ARCHIVE
const useArchiveIzobrazevanjeTip = () => {
    const { displayFeedback } = useContext(FeedbackContext);
    const { instance, accounts } = useMsal();
    const nav = useNavigate();
    const queryClient = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, number>((izobrazevanjeTipId) =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.put(`${AppConfig.baseApiUrl}/izobrazevanje_tip/archive/${izobrazevanjeTipId}`, null,
                { headers: { Authorization: `Bearer ${accessToken}` } })),
        {
            onSuccess: (_, izobrazevanjeTipId) => {
                queryClient.invalidateQueries(["izobrazevanjaTipi"]);
                nav("/sifrant/izobrazevanjeTip");
                if (displayFeedback)
                    displayFeedback("Tip izobraževanja je bil uspešno arhiviran.", Status.SUCCESS)
            },
            onError: (err) => {
                let errMsg = "Pri arhiviranju tipa izobraževanja je prišlo do napake."
                let apiException = getApiExceptionObjectFromAxiosError(err);
                if(apiException?.Message)
                    errMsg = apiException.Message;

                // TODO?: mogoče se lahko tu kaj poenoti za exception handling...
                if (displayFeedback)
                    displayFeedback(errMsg, Status.ERROR)
            }
        }
    );
}

// REACTIVATE
const useReactivateIzobrazevanjeTip = () => {
    const { displayFeedback } = useContext(FeedbackContext);
    const { instance, accounts } = useMsal();
    const nav = useNavigate();
    const queryClient = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, number>((izobrazevanjeTipId) =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.put(`${AppConfig.baseApiUrl}/izobrazevanje_tip/reactivate/${izobrazevanjeTipId}`, null,
                { headers: { Authorization: `Bearer ${accessToken}` } })),
        {
            onSuccess: (_, izobrazevanjeTipId) => {
                queryClient.invalidateQueries(["izobrazevanjaTipi"]);
                nav("/sifrant/izobrazevanjeTip");
                if (displayFeedback)
                    displayFeedback("Tip izobraževanja je bil uspešno aktiviran.", Status.SUCCESS)
            },
            onError: (err) => {
                let errMsg = "Pri aktiviranju tipa izobraževanja je prišlo do napake."
                let apiException = getApiExceptionObjectFromAxiosError(err);
                if(apiException?.Message)
                    errMsg = apiException.Message;

                // TODO?: mogoče se lahko tu kaj poenoti za exception handling...
                if (displayFeedback)
                    displayFeedback(errMsg, Status.ERROR)
            }
        }
    );
}

// DELETE
const useDeleteIzobrazevanjeTip = () => {
    const { displayFeedback } = useContext(FeedbackContext);
    const { instance, accounts } = useMsal();
    const nav = useNavigate();
    const queryClient = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, number>((izobrazevanjeTipId) =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.delete(`${AppConfig.baseApiUrl}/izobrazevanje_tip/${izobrazevanjeTipId}`,
                { headers: { Authorization: `Bearer ${accessToken}` } })),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["izobrazevanjaTipi"]);
                nav("/sifrant/izobrazevanjeTip");
                if (displayFeedback)
                    displayFeedback("Tip izobraževanja je bilo uspešno odstranjeno.", Status.SUCCESS)
            },
            onError: (err) => {
                let errMsg = "Pri brisanju tipa izobraževanja je prišlo do napake."
                let apiException = getApiExceptionObjectFromAxiosError(err);
                if(apiException?.Message)
                    errMsg = apiException.Message;

                // TODO?: mogoče se lahko tu kaj poenoti za exception handling...
                if (displayFeedback)
                    displayFeedback(errMsg, Status.ERROR)
            }
        }
    );
};

export default useFetchIzobrazevanjeTipAll;
export { useFetchIzobrazevanjeTip, useUpdateIzobrazevanjeTip, useAddIzobrazevanjeTip, useArchiveIzobrazevanjeTip, useReactivateIzobrazevanjeTip, useDeleteIzobrazevanjeTip };