import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from "react-router-dom";
import AppConfig from "../../AppConfig";
import { IzobrazevanjeListType } from "../../types/Izobrazevanje/IzobrazevanjeListType";
import { IzobrazevanjeFormType } from "../../types/Izobrazevanje/IzobrazevanjeFormType";
import { IzobrazevanjeWithSifrantiType } from "../../types/Izobrazevanje/IzobrazevanjeWithSifrantiType";
import { useMsal } from "@azure/msal-react";
import acquireToken from "../login/Authorization";
import { IzobrazevanjeDetailType } from "../../types/Izobrazevanje/IzobrazevanjeDetailType";
import { useContext } from "react";
import { FeedbackContext, Status } from "../../context/FeedbackContext";
import getApiExceptionObjectFromAxiosError from "../../utils/ProcessException";

// TODO: ena funkcija, ki izvaja klice, da se ne podvaja logika za vse klice

const useFetchIzobrazevanjeAll = () => {
    const { instance, accounts } = useMsal();
    const { displayFeedback } = useContext(FeedbackContext);
    const nav = useNavigate();
    return useQuery<IzobrazevanjeListType[], AxiosError>(["izobrazevanja"], () =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.get(`${AppConfig.baseApiUrl}/izobrazevanje/all`,
                { headers: { Authorization: `Bearer ${accessToken}` } })
                .then(function (resp) {
                    return resp.data
                })
        ));
};

const useFetchIzobrazevanje = (izobrazevanjeId: number) => {
    const { instance, accounts } = useMsal();
    return useQuery<IzobrazevanjeFormType, AxiosError>(["izobrazevanje", izobrazevanjeId], () =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.get(`${AppConfig.baseApiUrl}/izobrazevanje/${izobrazevanjeId}`,
                { headers: { Authorization: `Bearer ${accessToken}` } })
                .then(function (resp) {
                    return resp.data
                })
        ));
};

const useFetchIzobrazevanjeDetail = (izobrazevanjeId: number) => {
    const { instance, accounts } = useMsal();
    return useQuery<IzobrazevanjeDetailType, AxiosError>(["izobrazevanje", izobrazevanjeId], () =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.get(`${AppConfig.baseApiUrl}/izobrazevanje/detail/${izobrazevanjeId}`,
                { headers: { Authorization: `Bearer ${accessToken}` } })
                .then(function (resp) {
                    return resp.data
                })
        ));
};


const useFetchIzobrazevanjeWithSifranti = (izobrazevanjeId: number) => {
    const { instance, accounts } = useMsal();
    return useQuery<IzobrazevanjeWithSifrantiType, AxiosError>(["izobrazevanjeWithSifranti", izobrazevanjeId], () =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.get(`${AppConfig.baseApiUrl}/izobrazevanje/withSifranti/${izobrazevanjeId}`,
                { headers: { Authorization: `Bearer ${accessToken}` } })
                .then(function (resp) {
                    return resp.data
                })
        ));
};

const useAddIzobrazevanjeDraft = () => {
    const { displayFeedback } = useContext(FeedbackContext);
    const { instance, accounts } = useMsal();
    const nav = useNavigate();
    const queryClient = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, IzobrazevanjeFormType>((i) =>
        acquireToken(instance, accounts).then(function (accessToken) {
            return axios.post(`${AppConfig.baseApiUrl}/izobrazevanje`, i,
                { headers: { Authorization: `Bearer ${accessToken}` } })
                .then((resp) => resp.data.izobrazevanjeId)
        }),
        {
            onSuccess: (izobrazevanjeId) => {
                queryClient.invalidateQueries(["izobrazevanja"]);
                queryClient.invalidateQueries(["izobrazevanjeWithSifranti"]);
                nav(`/izobrazevanje/edit/${izobrazevanjeId}`);
                if (displayFeedback)
                    displayFeedback("Izobraževanje je bilo uspešno dodano.", Status.SUCCESS)
            },
            onError: (err) => {
                // TODO?: mogoče se lahko tu kaj poenoti za exception handling...
                let errMsg = "Prišlo je do napake."
                let apiException = getApiExceptionObjectFromAxiosError(err);
                if (apiException?.Message)
                    errMsg = apiException.Message;

                if (displayFeedback)
                    displayFeedback(errMsg, Status.ERROR)
            },
        }
    );
};

const useAddIzobrazevanjePublish = () => {
    const { displayFeedback } = useContext(FeedbackContext);
    const { instance, accounts } = useMsal();
    const nav = useNavigate();
    const queryClient = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, IzobrazevanjeFormType>((i) =>
        acquireToken(instance, accounts).then(function (accessToken) {
            return axios.post(`${AppConfig.baseApiUrl}/izobrazevanje/publish`, i,
                { headers: { Authorization: `Bearer ${accessToken}` } })
        }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["izobrazevanja"]);
                nav(`/izobrazevanje/list`);
                if (displayFeedback)
                    displayFeedback("Izobraževanje je bilo uspešno aktivirano.", Status.SUCCESS)
            },
            onError: (err) => {
                // TODO?: mogoče se lahko tu kaj poenoti za exception handling...
                if (displayFeedback)
                    displayFeedback("Prišlo je do napake.", Status.ERROR)
            }
        }
    );
};

const useUpdateIzobrazevanjeDraft = () => {
    const { displayFeedback } = useContext(FeedbackContext);
    const { instance, accounts } = useMsal();
    const queryClient = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, IzobrazevanjeFormType>((i) =>
        acquireToken(instance, accounts).then(function (accessToken) {
            // i.datumCasOd = new Date(i.datumCasOd)
            // i.datumCasDo = new Date(i.datumCasDo)
            return axios.put(`${AppConfig.baseApiUrl}/izobrazevanje/${i.izobrazevanjeId}`, i,
                { headers: { Authorization: `Bearer ${accessToken}` } })
        }),
        {
            onSuccess: (_, i) => {
                queryClient.invalidateQueries(["izobrazevanja"]);
                queryClient.invalidateQueries(["izobrazevanjeWithSifranti"]);
                // nav(`/izobrazevanje/list`);
                if (displayFeedback)
                    displayFeedback("Izobraževanje je bilo uspešno urejeno.", Status.SUCCESS)
            },
            onError: (err) => {
                let errMsg = "Prišlo je do napake."
                let apiException = getApiExceptionObjectFromAxiosError(err);
                if (apiException?.Message)
                    errMsg = apiException.Message;

                if (displayFeedback)
                    displayFeedback(errMsg, Status.ERROR)
            }
        }
    );
};

const useUpdateIzobrazevanjePublish = () => {
    const { displayFeedback } = useContext(FeedbackContext);
    const { instance, accounts } = useMsal();
    const nav = useNavigate();
    const queryClient = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, IzobrazevanjeFormType>((i) =>
        acquireToken(instance, accounts).then(function (accessToken) {
            // i.datumCasOd = new Date(i.datumCasOd)
            // i.datumCasDo = new Date(i.datumCasDo)
            return axios.put(`${AppConfig.baseApiUrl}/izobrazevanje/publish/${i.izobrazevanjeId}`, i,
                { headers: { Authorization: `Bearer ${accessToken}` } })
        }),
        {
            onSuccess: (_, i) => {
                queryClient.invalidateQueries(["izobrazevanja"]);
                queryClient.invalidateQueries(["izobrazevanjeWithSifranti"]);
                nav(`/izobrazevanje/list`);
                if (displayFeedback)
                    displayFeedback("Izobraževanje je bilo uspešno urejeno in aktivirano.", Status.SUCCESS)
            },
            onError: (err) => {
                let errMsg = "Prišlo je do napake."
                let apiException = getApiExceptionObjectFromAxiosError(err);
                if (apiException?.Message)
                    errMsg = apiException.Message;

                if (displayFeedback)
                    displayFeedback(errMsg, Status.ERROR)
            }
        }
    );
};

const useDeactivateIzobrazevanje = () => {
    const { displayFeedback } = useContext(FeedbackContext);
    const { instance, accounts } = useMsal();
    const nav = useNavigate();
    const queryClient = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, number>((id) =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.put(`${AppConfig.baseApiUrl}/izobrazevanje/deactivate/${id}`, null,
                { headers: { Authorization: `Bearer ${accessToken}` } })),
        {
            onSuccess: (_, id) => {
                //queryClient.invalidateQueries(["izobrazevanja"]);
                nav(`/izobrazevanje/edit/${id}`);
                if (displayFeedback)
                    displayFeedback("Izobraževanje je bilo uspešno deaktivirano.", Status.SUCCESS)
            },
            onError: (err) => {
                // TODO?: mogoče se lahko tu kaj poenoti za exception handling...
                if (displayFeedback)
                    displayFeedback("Prišlo je do napake.", Status.ERROR)
            }
        }
    );
};


const useDeleteIzobrazevanje = () => {
    const { displayFeedback } = useContext(FeedbackContext);
    const { instance, accounts } = useMsal();
    const nav = useNavigate();
    const queryClient = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, IzobrazevanjeFormType>((i) =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.delete(`${AppConfig.baseApiUrl}/izobrazevanje/${i.izobrazevanjeId}`,
                { headers: { Authorization: `Bearer ${accessToken}` } })),
        {
            onSuccess: (_) => {
                queryClient.invalidateQueries(["izobrazevanja"]);
                nav(`/izobrazevanje/list`);
                if (displayFeedback)
                    displayFeedback("Izobraževanje je bilo uspešno odstranjeno.", Status.SUCCESS)
            },
            onError: (err) => {
                if (displayFeedback)
                    displayFeedback("Prišlo je do napake.", Status.ERROR)
            }
        }
    );
};

export default useFetchIzobrazevanjeAll;
export { useFetchIzobrazevanje, useFetchIzobrazevanjeWithSifranti, useFetchIzobrazevanjeDetail, useAddIzobrazevanjeDraft, useAddIzobrazevanjePublish, useUpdateIzobrazevanjeDraft, useUpdateIzobrazevanjePublish, useDeactivateIzobrazevanje, useDeleteIzobrazevanje };