import "react-widgets/styles.css";
import DatePicker from "react-widgets/DatePicker";
import styled from 'styled-components';
import ApiException from "../../types/ApiException";
import timezoneShift from "../../utils/TimezoneShift";
import Localization from "react-widgets/esm/Localization";
//import DateFnsLocalizer from "react-widgets-date-fns";
import { DateLocalizer } from 'react-widgets/IntlLocalizer';
import DatePicker2 from "react-widgets/DatePicker";
import { useState } from "react";
//import { DateLocalizer, DropdownList } from "react-widgets/cjs";
import lang_fns from "date-fns/locale/sl";
//import DateFnsLocalizer from "react-widgets-date-fns";

var lang = navigator.language;

const StyledDateInput = styled.div`
    .first {
        margin-bottom: 0.5rem;
    }
    .rw-date-picker{
        width: fit-content;
        input:focus{
                outline: none;
            }
            input{
                box-shadow: none;
                border-radius: none;
            }
    }
    .rw-widget-container{
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
        margin-bottom: 0.4rem;
        width: 220px;
        .rw-input {
            width: 100%;
            color: black;
            font-size: 0.9rem;
            border: none;
            margin: 0;
        } 
        .rw-btn{
            background-color: ${props => props.theme.colors.background};
            padding: 0.3rem 0;
        }
    }
    .rw-state-focus{
        .rw-widget-container{
            box-shadow: none;
            border: 1px solid ${props => props.theme.colors.border};
            outline: 1px solid ${props => props.theme.colors.border};
        }
    }
    .rw-calendar-btn-today{
        display: none;
    } 
    .rw-state-selected{
        background-color: ${props => props.theme.colors.primary};
        border: none;
    }
    .rw-time-part-input{
        width: fit-content;
        margin: 0;
        border: none;
    }
`

interface DateInputArgs {
    label: string,
    odValue: Date | null | undefined,
    doValue: Date | null | undefined,
    odOnChange: (date: Date | null | undefined) => void,
    doOnChange: (date: Date | null | undefined) => void,
    err: ApiException | null,
    errField: string,
}


const DateInput = ({ label, odOnChange, doOnChange, odValue, doValue, err, errField }: DateInputArgs) => {

    //TODO: Poglej -> https://jquense.github.io/react-widgets/docs/localization#date-fns-date
    if (odValue)
        odValue = timezoneShift(odValue);
    if (doValue)
        doValue = timezoneShift(doValue);

    // const minDate = new Date();
    // minDate.setDate(minDate.getDate() + 3);
    //minDate.setHours(0,0,0);

    // console.log(minDate);

    return (
        <>
            <label htmlFor={label}>
                <span className="bold">
                    {label}:
                    <span className="red">*</span>
                </span>
            </label>
            <StyledDateInput>
                <Localization
                    date={new DateLocalizer({ culture: lang, firstOfWeek: 1 })}
                >
                    <DatePicker
                        defaultValue={odValue}
                        //value={odValue}
                        onChange={odOnChange}
                        placeholder="Od"
                        //valueFormat={{ dateStyle: "short", timeStyle: "short" }}
                        //min={minDate}
                        includeTime />
                    <DatePicker
                        defaultValue={doValue}
                        onChange={doOnChange}
                        //valueFormat={{ dateStyle: "short", timeStyle: "short" }}
                        placeholder="Od"
                        //min={odValue}
                        includeTime />
                </Localization>
            </StyledDateInput>
            
            {err && err.ValidationErrors && err.ValidationErrors[errField] &&
                <span className="error red">
                    {err.ValidationErrors[errField].map((m, i) => <span key={`message-${i}`}>{m}<br /></span>)}
                </span>
            }
        </>
    )
}

export default DateInput