import styled from 'styled-components';

const StyledLayout = styled.main`
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-rows: 75px 1fr;
    grid-template-columns: 210px 1fr;
    grid-template-areas: 
        "topbar topbar"
        "sidebar content"
    ;
    @media (max-width: 1000px) {
        #sidebar{
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100vw;
            z-index: 1;
            overflow: hidden;
        }
        grid-template-columns: 0 1fr;
    }
`

type LayoutArgs = {
    children?: React.ReactNode;
}

const Layout = ({ children }: LayoutArgs) => {
    return (
        <StyledLayout>
            {children}
        </StyledLayout>
    );
}

export default Layout;