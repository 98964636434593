import { format } from "date-fns"
import { useState } from "react"
import styled from "styled-components"
import IzobrazevanjeDetailForm from "../../pages/izobrazevanje/IzobrazevanjeDetailForm"
import IzobrazevanjePrijavaActions from "../../pages/prijava/IzobrazevanjePrijavaActions"
import { useAddIzobrazevanjePrijava, useFetchPrijavaByUporabnikIzobrazevanjeId, useUpdateIzobrazevanjePrijava } from "../../pages/prijava/IzobrazevanjePrijavaHooks"
import { IzobrazevanjeDetailType } from "../../types/Izobrazevanje/IzobrazevanjeDetailType"
import { IzobrazevanjeFormType } from "../../types/Izobrazevanje/IzobrazevanjeFormType"
import { IzobrazevanjePrijavaListDto } from "../../types/IzobrazevanjePrijava/IzobrazevanjePrijavaListDto"
import Nullable from "../../types/Nullable"
import Button from "./Button"
import Icon from "./Icon"

const StyledCardOverview = styled.div`
    .cardContent {
        width: 100%;
        display: flex;
        gap: 2rem;
        margin: 2rem 0;
    }

    @media (max-width: 900px){
        .cardContent{
            flex-direction: column;
        }
    }

`

interface CardOverviewArgs {
    izobrazevanje: Nullable<IzobrazevanjeDetailType>,
}

const CardOverview = ({ izobrazevanje, }: CardOverviewArgs) => {

    return (
        <StyledCardOverview>
            {izobrazevanje && <>
                <div className="cardContent">
                    <IzobrazevanjeDetailForm izobrazevanje={izobrazevanje} />
                    <IzobrazevanjePrijavaActions izobrazevanje={izobrazevanje} />
                </div>
            </>}
        </StyledCardOverview>
    )
}

export default CardOverview