import { useMsal } from "@azure/msal-react";
import styled from "styled-components"
import { LoginRequest } from "../../AppConfig";
import Button from "../../components/Common/Button"
import prijavaLogo from '../../assets/prijava_logo.png'
import ministrstvoLogo from '../../assets/mddsz_logo.png'

const StyledLoginPage = styled.div`
    height: 100vh;
    width: 100vw;
    background-color: ${props => props.theme.colors.secondary};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    .logo {
        width: min(400px, calc(35vw + 7rem));
    }
    .ministrstvo {
        width: min(300px, calc(28vw + 4rem));
    }
`

const LoginPage = () => {
    const { instance } = useMsal();

    const handleLogin: React.MouseEventHandler<HTMLButtonElement> = () => {
        instance.loginRedirect(LoginRequest);
    }

    return (
        <StyledLoginPage className='loginPage'>
            <img className='logo' src={prijavaLogo} alt="Logotip spletne strani" />
            <img className="ministrstvo" src={ministrstvoLogo} alt="Logotip ministrstva za delo, družino, socialne zadeve in enake možnosti" />
            <Button tertiary onClick={handleLogin}>Prijava</Button>
        </StyledLoginPage>
    )
}

export default LoginPage