import { AccessTokenRequest } from "../../AppConfig";
import { IPublicClientApplication } from "@azure/msal-browser";

const acquireToken = (instance: IPublicClientApplication, accounts: any[]) => {
    const request = {
        ...AccessTokenRequest,
        account: accounts[0]
    };

    // Silently acquires an access token which is then attached to a request for API data
    return instance.acquireTokenSilent(request)
        .then((response) => response.accessToken)
        .catch(() => instance.acquireTokenRedirect(request));
}

export default acquireToken;