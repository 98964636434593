import { IzobrazevanjeDetailType } from './../../types/Izobrazevanje/IzobrazevanjeDetailType';
import { IzobrazevanjePrijavaListDto } from '../../types/IzobrazevanjePrijava/IzobrazevanjePrijavaListDto';
import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from "react-router-dom";
import AppConfig from "../../AppConfig";
import groupArrayOfObjectsBy from "../../utils/Common";
import acquireToken from '../login/Authorization';
import { useMsal } from '@azure/msal-react';
import Nullable from '../../types/Nullable';
import { IzobrazevanjePrijavaUporabnikType } from '../../types/Izobrazevanje/IzobrazevanjePrijavaUporabnikType';
import getApiExceptionObjectFromAxiosError from '../../utils/ProcessException';
import { FeedbackContext, Status } from '../../context/FeedbackContext';
import { useContext } from 'react';

// TODO: ena funkcija, ki izvaja klice, da se ne podvaja logika za vse klice
type IzobrazevanjePrijavaUporabnikGroupedType = {
    [key: string]: IzobrazevanjePrijavaListDto[]
};

const useFetchUporabnikAktualno = () => {
    const { instance, accounts } = useMsal();
    return useQuery<IzobrazevanjePrijavaUporabnikGroupedType, AxiosError>(["izobrazevanjeAktualno"], () =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.get(`${AppConfig.baseApiUrl}/izobrazevanje/aktualno`,
                { headers: { Authorization: `Bearer ${accessToken}` } })
                .then((resp) => {
                    let prijaveGrouped = groupArrayOfObjectsBy(resp.data, "izobrazevanjeTipNaziv");
                    return prijaveGrouped;
                })
        ));
};

const useFetchUporabnikMojePrijave = () => {
    const { instance, accounts } = useMsal();
    return useQuery<IzobrazevanjePrijavaUporabnikGroupedType, AxiosError>(["izobrazevanjePrijave"], () =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.get(`${AppConfig.baseApiUrl}/izobrazevanje/mojePrijave`,
                { headers: { Authorization: `Bearer ${accessToken}` } })
                .then((resp) => {
                    let prijaveGrouped = groupArrayOfObjectsBy(resp.data, "izobrazevanjeTipNaziv");
                    return prijaveGrouped;
                })
        ));
};

const useFetchUporabnikZakljuceno = () => {
    const { instance, accounts } = useMsal();
    return useQuery<IzobrazevanjePrijavaUporabnikGroupedType, AxiosError>(["izobrazevanjeZakljuceno"], () =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.get(`${AppConfig.baseApiUrl}/izobrazevanje/zakljuceno`,
                { headers: { Authorization: `Bearer ${accessToken}` } })
                .then((resp) => {
                    let prijaveGrouped = groupArrayOfObjectsBy(resp.data, "izobrazevanjeTipNaziv");
                    return prijaveGrouped;
                })
        ));
};

const useFetchPrijavaByUporabnikIzobrazevanjeId = (izobrazevanjeId: Nullable<number>) => {
    const { instance, accounts } = useMsal();
    return useQuery<IzobrazevanjePrijavaListDto, AxiosError>(["izobrazevanjePrijave", izobrazevanjeId], () =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.get(`${AppConfig.baseApiUrl}/izobrazevanjePrijava/izobrazevanje/${izobrazevanjeId}`,
                { headers: { Authorization: `Bearer ${accessToken}` } })
                .then((resp) => resp.data)
        ));
};

const useFetchPrijavaByIzobrazevanjeId = (izobrazevanjeId: number) => {
    const { instance, accounts } = useMsal();
    return useQuery<IzobrazevanjePrijavaUporabnikType[], AxiosError>(["izobrazevanjePrijaveByIzobrazevanjeId", izobrazevanjeId], () =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.get(`${AppConfig.baseApiUrl}/izobrazevanjePrijava/${izobrazevanjeId}`,
                { headers: { Authorization: `Bearer ${accessToken}` } })
                .then((resp) => resp.data)
        ));
};

const useFetchPrijavaByIzobrazevanjeIdCsv = (izobrazevanjeId: number) => {
    const { displayFeedback } = useContext(FeedbackContext)
    const { instance, accounts } = useMsal();
    const { refetch } = useQuery<Blob, AxiosError>([izobrazevanjeId], () =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.get(`${AppConfig.baseApiUrl}/izobrazevanjePrijava/exportCsv/${izobrazevanjeId}`,
                { responseType: 'blob', headers: { Authorization: `Bearer ${accessToken}`}} )
                .then((resp) => resp.data)
            ), 
        { 
            enabled: false,
            retry: false,
            onSuccess: (data) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'izobrazevanjePrijave.csv');
                document.body.appendChild(link);
                link.click();
            },
            onError: (err) => {
                let errMsg = "Prišlo je do napake pri izvozu prijav v excel."
                let apiException = getApiExceptionObjectFromAxiosError(err);
                if (apiException?.Message)
                    errMsg = apiException.Message;
                
                if (displayFeedback)
                    displayFeedback(errMsg, Status.ERROR)
            },        
        }
    )
        
    return { refetch };
 };

const useAddIzobrazevanjePrijava = () => {
    const { instance, accounts } = useMsal();
    //const nav = useNavigate();
    const queryClient = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, number>((izobrazevanjeId) =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.post(`${AppConfig.baseApiUrl}/izobrazevanjePrijava/prijava/${izobrazevanjeId}`, { izobrazevanjeId: izobrazevanjeId },
                { headers: { Authorization: `Bearer ${accessToken}` } })),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["izobrazevanjePrijave"]);
                //nav(`/izobrazevanje/list`);
            },
            onError: (err) => {
                // TODO?: mogoče se lahko tu kaj poenoti za exception handling...
            }
        }
    );
};

const useUpdateIzobrazevanjePrijava = () => {
    const { instance, accounts } = useMsal();
    //const nav = useNavigate();
    const queryClient = useQueryClient();
    return useMutation<AxiosResponse, AxiosError, number>((izobrazevanjePrijavaId) =>
        acquireToken(instance, accounts).then((accessToken) =>
            axios.put(`${AppConfig.baseApiUrl}/izobrazevanjePrijava/odjava/${izobrazevanjePrijavaId}`, { izobrazevanjePrijavaId: izobrazevanjePrijavaId },
                { headers: { Authorization: `Bearer ${accessToken}` } })),
        {
            onSuccess: (_, i) => {
                queryClient.invalidateQueries(["izobrazevanjePrijave"]);
                //nav(`/izobrazevanje/list`);
            }
        }
    );
};

export { 
    useFetchUporabnikAktualno, 
    useFetchUporabnikMojePrijave,
    useFetchUporabnikZakljuceno,
    useFetchPrijavaByUporabnikIzobrazevanjeId,
    useFetchPrijavaByIzobrazevanjeId,
    useFetchPrijavaByIzobrazevanjeIdCsv,
    useAddIzobrazevanjePrijava,
    useUpdateIzobrazevanjePrijava };
    
export type { IzobrazevanjePrijavaUporabnikGroupedType };