import styled from 'styled-components'
import SidebarItem from './SidebarItem'
import { useState } from 'react'

interface StyledSidebarGroupArgs {
    isOpen: boolean
}

const StyledSidebarGroup = styled.div<StyledSidebarGroupArgs>`
    a {
        display: none;
    }
    ${props => props.isOpen && `
        .rotatable{
            transform: rotate(180deg);
        }
        a {
            display: block;
        }
    `}
`

interface SidebarGroupArgs {
    children: React.ReactNode,
    icon: string,
    name: string,
}

const SidebarGroup = ({ children, icon, name }: SidebarGroupArgs) => {

    const [isOpen, setIsOpen] = useState<boolean>(true)

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    return (
        <StyledSidebarGroup isOpen={isOpen}>
            <SidebarItem handleClick={toggleDropdown} icon={icon} isDropdown>{name}</SidebarItem>
            {children}
        </StyledSidebarGroup>
    )
}

export default SidebarGroup