import { useState } from "react";
import Button from "../../../components/Common/Button";
import Divider from "../../../components/Common/Divider";
import FormActions from "../../../components/Form/FormActions";
import FormContainer from "../../../components/Form/FormContainer";
import FormItems from "../../../components/Form/FormItems";
import Heading from "../../../components/Common/Heading";
import TextInput from "../../../components/Form/TextInput";
import ApiException from "../../../types/ApiException";
import { IzobrazevanjeTipFormType } from "../../../types/IzobrazevanjeTip/IzobrazevanjeTipFormType";
import { useNavigate } from "react-router";
import { IzobrazevanjeTipStatus } from "../../../utils/Enums";
import Modal from "../../../components/Common/Modal";

type IzobrazevanjeTipFormArgs = {
    izobrazevanjeTip: IzobrazevanjeTipFormType;
    submitted: (izobrazevanjeTip: IzobrazevanjeTipFormType) => void;
    submittedArchive?: (izobrazevanjeTip: IzobrazevanjeTipFormType) => void;
    submittedReactivate?: (izobrazevanjeTip: IzobrazevanjeTipFormType) => void;
    deleted?: (izobrazevanjeTip: IzobrazevanjeTipFormType) => void;
    apiException: ApiException | null;
}

const IzobrazevanjeTipForm = ({ izobrazevanjeTip, submitted, submittedArchive, submittedReactivate, deleted, apiException }: IzobrazevanjeTipFormArgs) => {
    const nav = useNavigate();
    const [izobrazevanjeTipState, setIzobrazevanjeTipState] = useState({ ...izobrazevanjeTip });
    const [archiveModal, setShowArchiveModal] = useState(false);
    const [deleteModal, setShowDeleteModal] = useState(false);
    
    const onSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        e.preventDefault();
        submitted(izobrazevanjeTipState);
    }

    const onSubmitArchive: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        if (submittedArchive) {
            e.preventDefault();
            submittedArchive(izobrazevanjeTipState);
        }
    }

    const onSubmitReactivate: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        if (submittedReactivate) {
            e.preventDefault();
            submittedReactivate(izobrazevanjeTipState);
            setShowArchiveModal(false);
        }
    }
    
    const showArchiveModal: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        e.preventDefault();
        setShowArchiveModal(true);
    }

    const showDeleteModal: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        e.preventDefault();
        setShowDeleteModal(true);
    }

    const onSubmitDelete: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        if (deleted) {
            e.preventDefault();
            deleted(izobrazevanjeTipState);
            setShowDeleteModal(false);
        }
    }

    return (
        <>
            <Heading>Urejanje tipa izobraževanja
                <Divider />
                <p>
                    <span className="red">*</span>
                    polja označena z zvezdico so obvezna
                </p>
            </Heading>
            <FormContainer>
                {/* arhiviraj se samo prikaže če je sifrant že ustvarjen */}
                {izobrazevanjeTip.status == IzobrazevanjeTipStatus.Aktivno && submittedArchive &&
                    <div>
                        <Modal 
                            visible={archiveModal}
                            message="Ali ste prepričani da želite arhivirati ta tip izobraževanja?"
                            actionMessage="arhiviraj"
                            actionIcon="archive"
                            onCancel={() => setShowArchiveModal(false)}
                            onConfirm={onSubmitArchive}
                        />
                        <FormActions>
                            <div></div>
                            <Button className="right" startIcon="archive" tertiary onClick={showArchiveModal}>Arhiviraj</Button>
                        </FormActions>
                    </div>
                }

                {izobrazevanjeTip.status == IzobrazevanjeTipStatus.Arhivirano &&
                    <div>
                        <FormActions>
                            <div></div>
                            <Button className="right" startIcon="archive" tertiary onClick={onSubmitReactivate}>Aktiviraj</Button>
                        </FormActions>
                        <p className="red">Izobraževanje tip je arhivirano. Za urejanje, ga je potrebno aktivirati.</p>
                    </div>
                }

                <FormItems labelsWidth="200px">
                    <TextInput
                        disabled={izobrazevanjeTipState.status == IzobrazevanjeTipStatus.Arhivirano ? true : false}
                        value={izobrazevanjeTipState.naziv ? izobrazevanjeTipState.naziv : ""}
                        width="350px"
                        placeholder="Naziv"
                        required
                        label="Naziv"
                        err={apiException}
                        errField="Naziv"
                        onChange={(e) => setIzobrazevanjeTipState({ ...izobrazevanjeTipState, naziv: e.target.value })}
                    />
                    <TextInput
                        disabled={izobrazevanjeTipState.status == IzobrazevanjeTipStatus.Arhivirano ? true : false}
                        width="100px"
                        placeholder="0"
                        label="Št. privzetih mest"
                        isWholeNumber
                        type="number"
                        min={0}
                        value={izobrazevanjeTipState.stMestPrivzeto == null ? "" : izobrazevanjeTipState.stMestPrivzeto}
                        err={apiException}
                        errField="StMestPrivzeto"
                        onChange={
                            (e) => setIzobrazevanjeTipState({ ...izobrazevanjeTipState, stMestPrivzeto: e.target.valueAsNumber })
                        }
                    />
                    <TextInput
                        disabled={izobrazevanjeTipState.status == IzobrazevanjeTipStatus.Arhivirano ? true : false}
                        width="100px"
                        placeholder="0"
                        label="Št. privzetih točk"
                        isWholeNumber
                        type="number"
                        min={0}
                        value={izobrazevanjeTipState.stTockPrivzeto == null ? "" : izobrazevanjeTipState.stTockPrivzeto}
                        err={apiException}
                        errField="stTockPrivzeto"
                        onChange={
                            (e) => setIzobrazevanjeTipState({ ...izobrazevanjeTipState, stTockPrivzeto: e.target.valueAsNumber })
                        }
                    />
                </FormItems>
                <FormActions>
                    {deleted &&
                        <div>
                            <Modal 
                                visible={deleteModal}
                                message="Ali ste prepričani da želite izbrisati ta tip izobraževanja?"
                                actionMessage="izbriši"
                                actionIcon="delete"
                                onCancel={() => setShowDeleteModal(false)}
                                onConfirm={onSubmitDelete}
                            />
                            <div></div>
                            <Button onClick={showDeleteModal} startIcon="delete" tertiary>izbriši</Button>
                        </div>
                    }
                    <div></div>
                    <div className="right">
                        {izobrazevanjeTipState.status != IzobrazevanjeTipStatus.Arhivirano 
                        && <Button startIcon="save" onClick={onSubmit}>shrani</Button>
                        }
                        <Button cancel startIcon="close" onClick={(e) => nav('/sifrant/izobrazevanjeTip')}>prekliči</Button>
                    </div>
                </FormActions>
            </FormContainer>
        </>
    );
}

export default IzobrazevanjeTipForm;