import styled from 'styled-components';

interface StyledInputArgs {
    width?: string,
    height?: string
}

const StyledTextArea = styled.textarea<StyledInputArgs>`
    min-width: ${props => props.width && props.width};
    max-width: ${props => props.width && props.width};
    min-height: ${props => props.height && props.height};
    border-radius: 4px;
    border: 1px solid #c3c3c3;
    padding: 0.4rem 0.8rem;
    margin: 0;
    margin-right: 1rem;
`

interface TextAreaArgs {
    placeholder?: string,
    value?: string | number,
    label: string,
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
    required?: boolean,
    width?: string,
    height?: string
}


const TextArea = ({ placeholder, value, label, onChange, required, width, height }: TextAreaArgs) => {
    return (
        <>
            <label htmlFor={label}>
                {required ?
                    <span className="bold">
                        {label}:
                        <span className="red">*</span>
                    </span> :
                    label + ':'
                }
            </label>
            <StyledTextArea
                width={width}
                height={height}
                placeholder={placeholder}
                value={value}
                onChange={onChange && function (e) { onChange(e) }} />
        </>
    )
}

export default TextArea