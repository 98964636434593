import React from 'react'
import styled from 'styled-components'

interface StyledProps {
    right?: boolean,
    full?: boolean,
}

const StyledIcon = styled.span<StyledProps>`
    transition: all .15s;
    user-select: none;
    margin-right: 0.5rem;
    color: white;
    &:hover{
        text-decoration: none !important;
    }
    ${props => props.right && `
        margin-left: auto;
        margin-right: 0;
    `}
    ${props => props.full && `
        font-variation-settings:
            'FILL' 1,
            'wght' 300,
            'GRAD' 0,
            'opsz' 48 !important;
    `}

`

interface Props {
    children?: React.ReactNode
    name: string,
    right?: boolean,
    full?: boolean,
    className?: string,
    onClick?: () => void,
}

const Icon = ({ name, right = false, children, full = false, className, onClick }: Props) => {
    const formattedName = name.replace(' ', '_').toLowerCase()
    const classes = `material-symbols-rounded icon ${className}`
    return (
        <StyledIcon onClick={onClick} full={full} right={right} className={classes} >
            {formattedName}
            {children}
        </StyledIcon >
    )
}

export default Icon