import { Navigate, Outlet } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import { useContext } from "react";
import { AuthContext, AuthContextProviderProps } from "../../context/AuthContext";
import { UserRole } from '../../utils/Enums';

const RequireAuth = (props: { allowedRols: [UserRole] } | {}) => {
    const isAuthenticated = useIsAuthenticated();
    const { loggedInUser } = useContext<AuthContextProviderProps>(AuthContext);
    let allowedRols: { allowedRols: [UserRole] } | null = null;
    if (Object.keys(props).length > 0)
        allowedRols = props as { allowedRols: [UserRole] };

    return (
        isAuthenticated
            ? (!loggedInUser
                ? <></>
                : (allowedRols == null || !allowedRols?.allowedRols?.includes(UserRole.Administrator) || loggedInUser.isAdministrator
                ? <Outlet />
                    : <Navigate to="/unauthorized" />))
            : <Navigate to="/notloggedin" />
    );
}

export default RequireAuth;