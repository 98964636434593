import styled from 'styled-components'

const StyledTabMenu = styled.div`
    border-bottom: 3px solid #e8e8e8;
    margin-bottom: 2rem;
    user-select: none;
    div {
        margin-right: calc(1rem + 4vw);
    }
`

interface TabMenuArgs {
    children: React.ReactNode
}

const TabMenu = ({ children }: TabMenuArgs) => {
    return (
        <StyledTabMenu>
            {children}
        </StyledTabMenu>
    )
}

export default TabMenu