import styled from 'styled-components'

const StyledSection = styled.section`
    grid-area: content;
    padding: 1rem calc(0.5rem + 2.5vw);
    overflow-y: auto;
    .content{
        width: 100%;
    }
`

interface SectionArgs {
    children: React.ReactNode
}

const Section = ({ children }: SectionArgs) => {
    return (
        <StyledSection id='main'>
            <div className='content'>
                {children}
            </div>
        </StyledSection>
    )
}

export default Section