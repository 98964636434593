import styled from 'styled-components'
import ApiException from '../../types/ApiException';

const StyledLabel = styled.label`
    margin-top: -3px !important;
    input{
        margin-bottom: 0.5rem;
    }
`

interface CheckboxInputProps {
    label: string;
    children?: React.ReactNode;
    err?: ApiException | null,
    errField: string,
}

const CheckboxInput = ({ label, children, err, errField }: CheckboxInputProps) => {
    return (
        <>
            <StyledLabel
                htmlFor={label}>
                <span className="bold">
                    {label}:
                    <span className="red">*</span>
                </span>
            </StyledLabel>
            <div>
                {children}
            </div>
            {err && err.ValidationErrors && err.ValidationErrors[errField] &&
                <span className="error red">{err.ValidationErrors[errField]}</span>
            }
        </>
    )
}

export default CheckboxInput