import styled from 'styled-components'
import Icon from './Icon'

interface StyledButtonArgs {
    primary?: boolean,
    tertiary?: boolean,
    cancel?: boolean
}

const StyledButton = styled.button<StyledButtonArgs>`
    border: none;
    padding: 0.3rem .9rem;
    transition: all .15s;
    background-color: ${props => props.primary ? props.theme.colors.primary : props.tertiary ? props.theme.colors.tertiary : props.cancel ? props.theme.colors.cancel : props.theme.colors.primary};
    border-radius: 4px;
    color: white;
    font-weight: 600;
    font-size: 0.9rem;
    text-transform: lowercase;
    display: inline-flex;
    align-items: center;
    user-select: none;

    ${props => props.disabled && `
        background-color: #a8a8a8;
        *{
            color: #e8e8e8;
        }
        pointer-events: none;
    `}

    .text{
        margin-bottom: 2px;
        display: inline-flex;
        align-items: center;
        height: min-content;
    }

    span {
        font-size: 1.5rem;
    }

    &:hover {
        cursor: pointer;
        transform: translateY(-2px);
    }
    &:active {
        transform: scale(0.98);
    }
`

interface ButtonArgs {
    children: React.ReactNode
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
    startIcon?: string,
    className?: string,
    primary?: boolean,
    tertiary?: boolean,
    cancel?: boolean,
    disabled?: boolean
}

const Button = ({ children, onClick, className, primary, tertiary, cancel, startIcon, disabled }: ButtonArgs) => {
    return (
        <StyledButton disabled={disabled} primary={primary} tertiary={tertiary} cancel={cancel} className={`button ${className}`} onClick={onClick}>
            {startIcon && <Icon name={startIcon} />}
            <div className='text'>
                {children}
            </div>
        </StyledButton>
    )
}

export default Button