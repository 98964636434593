import { useState } from 'react';
import styled from 'styled-components'
import Paginate from './Paginate';

interface StyledTableArgs {
    fullWidth?: boolean;
}

const StyledTable = styled.div`
    overflow-x: auto;
    margin-bottom: 1.5rem;
    table{
        border-spacing: 0;
        width: 100%;
    }

    tbody{
        tr:nth-child(odd) {background: #f0f0f0}
    }

    a{
        color: ${props => props.theme.colors.primary};
        text-decoration: underline;
    }
    a:hover{
        font-weight: 600;
        letter-spacing: -0.2px;
    }

    thead {
        background-color: ${props => props.theme.colors.primary};;
        color: white;
    }
    th, td {
        padding: 0.5rem 3rem 0.5rem 1rem;
        border-bottom: 1px solid #b8b8b8;
    }
    th{
        position: relative;
        user-select: none;
        span{
            position: absolute;
            top: 50%;
            transform: translateY(-45%);
        }
    }
    th:hover{
        cursor: pointer;
    }
    .txtLeft{
        text-align: left;
    }
    .txtCenter{
        text-align: center;
    }
    .container{
        width: fit-content;
    }
    ${(props: StyledTableArgs) => props.fullWidth && `
        .container{
            min-width: 100%;
        }
    `}
`

interface TableArgs {
    children: React.ReactNode,
    fullWidth?: boolean,
    pageCount?: number,
    onPageChange?: (selectedItem: { selected: number }) => void,
    paginate?: boolean,
    setPaginate?: React.Dispatch<React.SetStateAction<boolean>>
}

const Table = ({ children, fullWidth, pageCount, onPageChange, paginate, setPaginate }: TableArgs) => {

    return (
        <StyledTable fullWidth={fullWidth}>
            <div className='container'>
                <table>{children}</table>
                {
                    pageCount && onPageChange && pageCount > 1 &&
                    <Paginate
                        paginate={paginate}
                        pageCount={pageCount}
                        onPageChange={onPageChange}
                        setPaginate={setPaginate}
                    />
                }
            </div>
        </StyledTable>
    )
}

export default Table